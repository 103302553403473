/** @format */

import { Grid, Menu, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { get_recentmessage_time } from "../../helpers/Firebasehelpers";
import Customizedbutton from "@components/Customizedbutton";
import { display_date } from "@helpers/Helperfunctions";
import { styled, alpha } from "@mui/material/styles";
import ImageIcon from "@mui/icons-material/Image";
import VideocamIcon from "@mui/icons-material/Videocam";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { handleDownload_document } from "@helpers/Helperfunctions";
import BellIcon from "../icons/BellIcon";
import CastingCallsIcon from "../icons/CastingCallsIcon";
import CompetitionsIcon from "../icons/CompetitionsIcon";
import WorksIcon from "../icons/WorksIcon";
import FavoriteIcon from "../icons/FavoriteIcon";
import EstimationListIcon from "../icons/EstimationListIcon";
import ProjectListIcon from "../icons/ProjectListIcon";
import StarIcon from "../icons/StarIcon";
import Microlink from "@microlink/react";
import { Regex_exports } from "../../exports/Regex_eports";
import { price_formatting } from "../../helpers/Helperfunctions";
import VideoPlayButton from "../VideoPlayButton";
import { SparkLineChart } from "@mui/x-charts";
import {
  file_formate_display,
  variantColors,
  variantIcons,
  variantTitles,
} from "./Userconstants";
import { useSelector } from "react-redux";
import upload_image from "@images/gallary_image.svg";
import CommonImage from "@components/CommonImage";
import ArtistsCardSkeleton from "../cards/ArtistsCardSkeleton";
import CastingCallRequestCard from "../cards/CastingCallRequestCard";
import ReviewsSkeleton from "../cards/ReviewsSkeleton";
import PricingCardSkeleton from "../cards/PricingCardSkeleton";
import { ServiceCardSkeleton } from "../cards/ServiceCardSkeleton";
import { FindWorkCardSkeleton } from "../cards/FindWorkCardSkeleton";
import { CastingcallCardSkeleton } from "../cards/CastingcallCardSkeleton";
import { CompetitionCardSkeleton } from "../cards/CompetitionCardSkeleton";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { IconButton, Box } from "@mui/material";
import { CheckCircle, Error, Warning, Info, Close } from "@mui/icons-material";
import { style_exports } from "../../exports/style_exports";

export const document = (message, type, progress) => (
  <Grid
    item
    container
    direction={"column"}
    sx={{
      // width: "14vw",
      backgroundColor: "#F2F2F2",
      padding: "0.5rem",
      borderRadius: "1rem",
      cursor: "pointer",
    }}
    // spacing={1}
    gap={1}
    onClick={() =>
      handleDownload_document(
        message?.fileUrl,
        message?.fileName || message?.name
      )
    }
  >
    <Grid
      container
      item
      alignItems={"center"}
      sx={{
        background: "#ffff",
        borderRadius: "0.5rem",
        padding: "0.5rem",
      }}
    >
      <Grid
        item
        xs={"auto"}
        container
        alignItems={"center"}
        justifyContent={"center"}
      >
        {/* height="50%" */}
        {/* <CommonImage src={ppt} alt="ppt" width="70%" /> */}
        {/* {console.log(message,"messages")} */}
        {/* {console.log(message?.fileType,"file type")} */}
        {/* {console.log(file_formate_display[message?.fileType??"ppt"])} */}
        {file_formate_display[message?.fileType ?? "ppt"]}
      </Grid>
      <Grid
        item
        xs
        sx={{
          fontSize: "14px",
          paddingRight: "0.5rem",
          maxWidth: "calc(100% - 34px) !important",
        }}
      >
        <Typography
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {message?.fileName || message?.name}
        </Typography>
      </Grid>
    </Grid>
    <Grid item container justifyContent={"space-between"}>
      <Grid item sx={{ fontSize: "10px" }}>
        {message?.fileSize || message?.size}
      </Grid>
      <Grid item sx={{ fontSize: "10px" }}>
        {type === 2
          ? `${progress.toFixed(2)}%`
          : get_recentmessage_time(message?.createdAt)}
      </Grid>
    </Grid>
  </Grid>
);

/**Display image */
export const disp_image = (message) => (
  <CommonImage
    src={message?.fileUrl}
    alt={message?.fileName}
    // width="100px"
    // height="100px"
    style={{
      width: "100%",
      aspectRatio: "1/1",
      objectFit: "cover",
      borderRadius: "0.5rem",
    }}
  />
);

export const disp_video = (message) => (
  <div style={{ position: "relative" }}>
    <video
      src={message?.fileUrl} //thumbUrl
      alt={message?.fileName}
      // style={{ width: "100px" }}
      style={{
        width: "100%",
        aspectRatio: "1/1",
        objectFit: "cover",
        borderRadius: "0.5rem",
      }}
    />
    <Grid
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // background: "black",
        // color: "white",
        // borderRadius: "50%",
      }}
    >
      <VideoPlayButton width={"35px"} height={"35px"} />
    </Grid>
  </div>
);

/**User chatbox message text */
export const display_message = (
  message,
  msg_type,
  handlerequestquote,
  userid,
  selected,
  user_data,
  loggeduser_details,
  application_currency,
  deactivated
) => {
  let image_render = false;
  switch (msg_type) {
    case 1:
    case 5:
      return render_text_message(
        message,
        msg_type,
        handlerequestquote,
        userid,
        selected,
        user_data
      );
      break;
    case 2:
      // const nextImage = new Image();
      // nextImage.src = message?.fileUrl;
      // nextImage.onload = () => {
      //   console.log("image")

      // };
      return (
        <>
          {
            <CommonImage
              src={message?.fileUrl || upload_image}
              alt={message?.fileName}
              key={message?.fileUrl}
              onLoad={(event) => (event.target.style.opacity = 1)}
              style={{ width: "200px", borderRadius: "0.5rem", opacity: 0 }}
            />
          }
        </>
      );
      break;
    case 3:
      return render_video_message(message);
      break;
    case 4:
      return document(message);
      break;
    case 6:
      return Render_request_quote({
        message,
        msg_type,
        handlerequestquote,
        userid,
        selected,
        user_data,
        loggeduser_details,
        application_currency,
        deactivated,
      });
      break;
    case 7:
      return Render_send_quote({
        message,
        msg_type,
        handlerequestquote,
        userid,
        selected,
        user_data,
        loggeduser_details,
        application_currency,
      });
      break;
    case 8:
    case 9:
      return render_castingcompetition_stautus({ message, msg_type, userid });
      break;
    default:
      return null;
  }
};

/**Text message displaying */
const render_text_message = (
  message,
  msg_type,
  handlerequestquote,
  userid,
  selected,
  user_data
) => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: message?.senderID === userid ? "#ffeeb6" : "#D8F4FD",
        padding: "0.4rem 0.7rem",
        borderRadius: "0.7rem",
        maxWidth: "100%",
        wordBreak: "break-word",
      }}
      direction="column"
    >
      {/* {console.log(user_data,"user data")} */}
      {selected?.chatType === 2 && (
        <Grid item sx={{ fontWeight: "700", fontSize: "15px" }}>
          {user_data?.name ?? ""}
        </Grid>
      )}{" "}
      <Grid
        item
        container
        alignItems="flex-end"
        justifyContent="space-between"
        gap={1}
      >
        {msg_type === 1 ? (
          <Grid item container sx={{ whiteSpace: "pre-line" }}>
            {message?.text}
          </Grid>
        ) : (
          message?.text && (
            <>
              <Grid item>
                <div
                  dangerouslySetInnerHTML={{
                    __html: message?.text?.replace(
                      Regex_exports.urlRegex,
                      '<a href="$&" target="_blank">$&</a>'
                    ),
                  }}
                ></div>
              </Grid>
              <Grid item sx={{ width: "inherit" }}>
                <Microlink
                  url={message?.text?.match(Regex_exports.urlRegex)[0]}
                  autoPlay={false}
                />
              </Grid>
            </>
          )
        )}
        <Grid
          item
          style={{
            fontSize: "10px",
            color: "#636373",
            fontWeight: "400",
          }}
          container
          justifyContent={"flex-end"}
        >
          {get_recentmessage_time(message?.createdAt)}
        </Grid>
      </Grid>
    </Grid>
  );
};
/**Video message displaying */
const render_video_message = (message) => {
  return (
    <div style={{ width: "200px", position: "relative" }}>
      <CommonImage
        src={message?.thumbUrl}
        alt={message?.fileName}
        style={{
          width: "200px",
          aspectRatio: 16 / 9,
          borderRadius: "0.5rem",
          objectFit: "contain",
          backgroundColor: style_exports?.grey_color,
        }}
      />
      <Grid
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // background: "black",
          // color: "white",
          // borderRadius: "50%",
        }}
      >
        <VideoPlayButton width={"35px"} height={"35px"} />
      </Grid>
    </div>
  );
};
/**Request quote message */
const Render_request_quote = ({
  message,
  msg_type,
  handlerequestquote,
  userid,
  selected,
  user_data,
  loggeduser_details,
  application_currency,
  deactivated,
}) => {
  return (
    <Paper
      sx={{
        // width: "203px",
        maxWidth: "100%",
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: message?.senderID === userid ? "#ffeeb6" : "#D8F4FD",
        // opacity:message?.senderID === userid ?"45%":0
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item sx={{ fontSize: "14px", color: "#464646", fontWeight: 300 }}>
          {/* Requesting Quote */}
          Job Request Quote
        </Grid>
        <Grid item>
          <span
            style={{
              fontSize: "14px",
              color: "#464646",
              fontWeight: 700,
            }}
          >
            {" "}
            Project Name
          </span>
          <Grid
            item
            sx={{ fontSize: "14px", color: "#464646", fontWeight: 500 }}
          >
            {message?.quoteInfo?.projectName ?? ""}
          </Grid>
        </Grid>
        <Grid item>
          <span
            style={{
              fontSize: "14px",
              color: "#464646",
              fontWeight: 700,
            }}
          >
            {" "}
            Project Type
          </span>
          <Grid
            item
            sx={{ fontSize: "14px", color: "#464646", fontWeight: 500 }}
          >
            {message?.quoteInfo?.projectType ?? ""}
          </Grid>
        </Grid>
        <Grid item>
          <span
            style={{
              fontSize: "14px",
              color: "#464646",
              fontWeight: 700,
            }}
          >
            {" "}
            Subcategory
          </span>
          <Grid
            item
            sx={{ fontSize: "14px", color: "#464646", fontWeight: 500 }}
          >
            {message?.quoteInfo?.subCategory ?? ""}
          </Grid>
        </Grid>
        <Grid item>
          <span
            style={{
              fontSize: "14px",
              color: "#464646",
              fontWeight: 700,
            }}
          >
            {" "}
            Project Details
          </span>
          <Grid item sx={{ whiteSpace: "pre-line" }}>
            {message?.quoteInfo?.projectDetails ?? ""}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item container direction={"column"} md={6} xs>
            <Grid
              item
              sx={{ fontSize: "12px", color: "#333333", fontWeight: 400 }}
            >
              Starts
            </Grid>
            <Grid
              item
              sx={{ fontSize: "14px", color: "#333333", fontWeight: 700 }}
            >
              {message?.quoteInfo?.fromDate.replace(/-/g, "/")}
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={"column"}
            md={6}
            xs
            alignItems={"center"}
          >
            <Grid
              item
              fontSize={"12px"}
              fontWeight={400}
              sx={{ color: "#333333" }}
            >
              Ends
            </Grid>
            <Grid
              item
              fontSize={"14px"}
              fontWeight={700}
              sx={{ color: "#333333" }}
            >
              {message?.quoteInfo?.toDate.replace(/-/g, "/")}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ fontSize: "14px", color: "#333333", fontWeight: 400 }}>
          Payment term:{" "}
          {message?.quoteInfo?.paymentTerm === "1"
            ? `No. of Days (${message?.quoteInfo?.daysNeeded})`
            : "Project"}
        </Grid>
        {message?.quoteInfo?.daysNeeded &&
        message?.quoteInfo?.paymentTerm === "1" ? (
          <Grid
            item
            sx={{ fontSize: "14px", color: "#333333", fontWeight: 400 }}
          >
            Rate per day: {Math.round(message?.quoteInfo?.budget)}
            {/* //{Math.round(message?.quoteInfo?.budget)} */}
          </Grid>
        ) : null}
        <Grid item container>
          <Grid sx={{ fontSize: "14px", color: "#333333", fontWeight: 400 }}>
            Total:
            {/* &nbsp; */}
          </Grid>
          <Grid
            item
            sx={{ fontSize: "14px", color: "#333333", fontWeight: 700 }}
          >
            <span style={{ paddingLeft: "0.4rem" }}>
              {message?.quoteInfo?.paymentTerm === "1"
                ? price_formatting(
                    parseInt(message?.quoteInfo?.daysNeeded) *
                      Math.round(parseFloat(message?.quoteInfo?.budget)),
                    application_currency
                  )
                : // message?.quoteInfo?.budget
                  price_formatting(
                    Math.round(message?.quoteInfo?.budget),
                    application_currency
                  )}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item mt={1}>
          <Customizedbutton
            data={message?.senderID === userid ? "Requested" : "Send Quote"}
            // swidth={"123px"}
            // height="23px"
            size={"lg"}
            disabled={
              message?.quoteInfo?.sentStatus ||
              message?.quoteInfo?.isDisabled ||
              deactivated
            }
            bgcolor={
              message?.quoteInfo?.sentStatus ||
              message?.quoteInfo?.isDisabled ||
              deactivated
                ? "#00c2ff52" //"#F2F2F2"
                : "#04BEF8"
            }
            styles={{ borderRadius: "0.5rem", fontWeight: "700" }}
            onClick={
              message?.quoteInfo?.senderId !== userid
                ? () => handlerequestquote(message)
                : () => {}
            }
          />
        </Grid>
        <Grid
          item
          style={{
            fontSize: "10px",
            color: "#636373",
            fontWeight: "400",
          }}
        >
          {get_recentmessage_time(message?.createdAt)}
        </Grid>
      </Grid>
    </Paper>
  );
};
/**Send quote message */
const Render_send_quote = ({
  message,
  msg_type,
  handlerequestquote,
  userid,
  selected,
  user_data,
  loggeduser_details,
  application_currency,
}) => {
  return (
    <Paper
      sx={{
        width: "203px",
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: message?.senderID === userid ? "#ffeeb6" : "#D8F4FD",
        // opacity:message?.senderID === userid ?"45%":0
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item sx={{ fontSize: "12px", color: "#464646", fontWeight: 300 }}>
          {message?.quoteInfo?.senderId === userid
            ? "Receiving quote"
            : "Sending quote"}{" "}
        </Grid>
        <Grid item container direction="column" spacing={0.5}>
          <Grid item sx={{ fontSize: "1rem" }}>
            Project Name
          </Grid>
          <Grid
            item
            sx={{ fontSize: "12px", color: "#464646", fontWeight: 700 }}
          >
            {message?.quoteInfo?.projectName ?? ""}
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={0.5}>
          <Grid item sx={{ fontSize: "1rem" }}>
            Project Type
          </Grid>
          <Grid
            item
            sx={{ fontSize: "12px", color: "#464646", fontWeight: 700 }}
          >
            {message?.quoteInfo?.projectType ?? ""}
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={0.5}>
          <Grid item sx={{ fontSize: "1rem" }}>
            Subcategory
          </Grid>
          <Grid
            item
            sx={{ fontSize: "12px", color: "#464646", fontWeight: 700 }}
          >
            {message?.quoteInfo?.subCategory ?? ""}
          </Grid>
        </Grid>
        <Grid item container direction={"column"}>
          <Grid
            item
            sx={{ fontSize: "1rem", color: "#333333", fontWeight: 400 }}
          >
            Comment
          </Grid>
          <Grid
            item
            sx={{ fontSize: "14px", color: "#333333", fontWeight: 700 }}
          >
            {message?.quoteInfo?.comment ?? ""}
          </Grid>
        </Grid>
        <Grid item sx={{ fontSize: "1rem", color: "#333333", fontWeight: 400 }}>
          Estimate Total
        </Grid>
        <Grid item sx={{ fontSize: "14px", color: "#333333", fontWeight: 700 }}>
          {/* {loggeduser_details?.country?.currencySymbol} */}
          <span style={{ paddingLeft: "0.4rem" }}>
            {
              // message?.quoteInfo?.budget
              price_formatting(
                Math.round(message?.quoteInfo?.budget),
                application_currency
              )
            }
          </span>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid
          item
          style={{
            fontSize: "10px",
            color: "#636373",
            fontWeight: "400",
          }}
        >
          {get_recentmessage_time(message?.createdAt)}
        </Grid>
      </Grid>
    </Paper>
  );
};
/**Application status message for castingcall,competition */
const render_castingcompetition_stautus = ({ message, msg_type, userid }) => {
  const {
    applicationStatusMesg: { message: display_msg, projectName, role },
  } = message;
  return (
    <Paper
      sx={{
        // width: "303px",
        maxWidth: "100%",
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: message?.senderID === userid ? "#ffeeb6" : "#D8F4FD",
        // opacity:message?.senderID === userid ?"45%":0
      }}
    >
      <Stack>
        <Grid item fontWeight={"700"}>
          {msg_type === 8 ? "Casting Call Status" : "Competition Status"}
        </Grid>
        <Grid item>
          Project Name: <span style={{ fontWeight: 700 }}>{projectName}</span>
        </Grid>
        {msg_type === 8 ? (
          <Grid item>
            Role: <span style={{ fontWeight: 700 }}>{role}</span>
          </Grid>
        ) : null}
        {/* <Grid item>{display_msg}</Grid> */}
        <div style={{ whiteSpace: "pre-line", marginTop: "0.5rem" }}>
          {display_msg}
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: display_msg.replace(/\n/g, '<br>') }} /> */}
      </Stack>
      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid
          item
          style={{
            fontSize: "10px",
            color: "#636373",
            fontWeight: "400",
          }}
        >
          {get_recentmessage_time(message?.createdAt)}
        </Grid>
      </Grid>
    </Paper>
  );
};
/**user chat tab recent message display */
export const recent_message_display = (userDetails, user_data) => {
  const { recentMessage } = userDetails;

  if (!recentMessage) return null;
  switch (recentMessage?.contentType) {
    case 1:
    case 5:
      return recentMessage?.text;
    case 2:
      return renderIcon("Image", <ImageIcon fontSize="50%" />);
    case 3:
      return renderIcon("Video", <VideocamIcon fontSize="50%" />);
    case 4:
      return renderIcon("Docs", <TextSnippetIcon fontSize="50%" />);
    case 6:
      return recentMessage?.quoteInfo?.senderId === user_data?.id
        ? "Requested Quote"
        : "Requesting Quote";
    case 7:
      return recentMessage?.quoteInfo?.senderId === user_data?.id
        ? "Received Quote"
        : "Sent Quote";
    case 8:
      return "Casting call Status";
    case 9:
      return "Competition Status";
    default:
      return null;
  }
};
function renderIcon(label, icon) {
  return (
    <>
      {icon}
      &nbsp;{label}
    </>
  );
}

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "1rem 0rem 1rem 1rem",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "0px 4px 4px 0px #00000040",
    //'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      color: "#04BEF8",
      "&:not(:last-child)": {
        borderBottom: "1px solid #F2F2F2",
      },
    },
  },
}));

const getNotFoundMessage = (type) => {
  const messages = [
    {
      id: 1,
      title: "No Notifications Found",
      description: "",
      icon: <BellIcon width="50px" height="50px" />,
    },
    {
      id: 2,
      title: "No Applications Found",
      description:
        "Once you apply for a casting call, your submissions will be displayed here",
      icon: <CastingCallsIcon width="50px" height="50px" />,
    },
    {
      id: 3,
      title: "No Applications Found",
      description:
        "Once you apply for a competitions, your submissions will be displayed here",
      icon: <CompetitionsIcon width="50px" height="50px" />,
    },
    {
      id: 4,
      title: "No Works Found",
      description:
        "Your submitted requests from the 'Post a Request' section will appear here",
      icon: <WorksIcon width="50px" height="50px" />,
    },
    {
      id: 5,
      title: "No Casting Calls Found",
      description:
        "Your posted Casting Calls from the 'Casting Calls' section will appear here",
      icon: <CastingCallsIcon width="50px" height="50px" />,
    },
    {
      id: 6,
      title: "No Favourites Found",
      description: "",
      icon: <FavoriteIcon width="50px" height="50px" />,
    },
    {
      id: 7,
      title: "No Estimations Found",
      description:
        "To include a service/person in your estimation list, please create a project first.",
      icon: <EstimationListIcon width="50px" height="50px" />,
    },
    {
      id: 8,
      title: "No Estimations Found",
      description:
        "To include a service/person in your estimation list, please create a project first.",
      icon: <EstimationListIcon width="50px" height="50px" />,
    },
    {
      id: 9,
      title: "No Projects Found",
      description:
        "You can create a project while adding a person to the estimation list within the details screen of the user",
      icon: <ProjectListIcon width="50px" height="50px" />,
    },
    {
      id: 10,
      title: "No Ratings Received",
      description:
        "To receive ratings, make sure you actively participate in others projects",
      icon: <StarIcon width="50px" height="50px" />,
    },
    {
      id: 11,
      title: "No Ratings Given",
      description:
        "The ratings you've submitted will appear here. To rate others, make sure you've successfully completed a project",
      icon: <StarIcon width="50px" height="50px" />,
    },
  ];
  return messages[type - 1];
};

export const emptyMessage = (type, emptyHeight) => {
  if (!type) {
    return "";
  }
  const message = getNotFoundMessage(type);
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: emptyHeight ? "300px" : "100%" }}
      >
        <Grid
          sx={{
            width: "400px",
            maxWidth: "100%",
            textAlign: "center",
            background: "#f6f6f6",
            padding: "1rem",
            borderRadius: "1rem",
            color: "#484848",
            "@media screen and (min-width: 300px) and (max-width: 599px)": {
              width: "250px",
            },
          }}
          container
          gap={1}
        >
          <Grid item xs={12}>
            {message?.icon}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              fontWeight: 700,
              fontSize: "18px",
            }}
          >
            {message?.title}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              fontWeight: 400,
              fontSize: "14px",
            }}
          >
            {message?.description}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const ChatEmptyMessage = (messageType, message) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bgcolor="#c9c9c9"
      color="white"
      textAlign="center"
      padding={"1rem"}
      borderRadius={"1rem"}
    >
      <svg
        width="90"
        height="90"
        viewBox="0 0 90 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.0715 59.3275H30.7544V68.8828L42.6979 59.3275H54.1686C56.7511 59.3275 58.8514 57.2273 58.8514 54.6447V35.9133C58.8514 33.3307 56.7511 31.2305 54.1686 31.2305H26.0715C23.4889 31.2305 21.3887 33.3307 21.3887 35.9133V54.6447C21.3887 57.2273 23.4889 59.3275 26.0715 59.3275Z"
          fill="white"
        />
        <path
          d="M63.5338 21.8647H35.4367C32.8542 21.8647 30.7539 23.965 30.7539 26.5476H58.8509C61.4335 26.5476 63.5338 28.6478 63.5338 31.2304V49.9618C66.1164 49.9618 68.2166 47.8615 68.2166 45.2789V26.5476C68.2166 23.965 66.1164 21.8647 63.5338 21.8647Z"
          fill="white"
        />
        <circle
          cx="44.8022"
          cy="45.2793"
          r="43.2124"
          stroke="white"
          stroke-width="3"
        />
      </svg>
      <Typography variant="h6" fontSize={"1.1rem"} fontWeight="bold">
        {messageType === 3
          ? message // custom message
          : messageType === 2 // for archived chats are 0
          ? "You dont have any Archived chats."
          : "Select one of the chats to start messaging."}
      </Typography>
    </Box>
  );
};

export const adminDashboardUserNames = (userCount) => {
  const lineChartColors = [
    "#04BEF8",
    "#BF0071",
    "#0056BF",
    "#FF3FD1",
    "#FF903F",
    "#5DFF15",
    "#BF0071",
  ];
  return (
    <>
      {Object.entries(userCount)?.map(([key, value], index) => {
        if (key !== "totalUserCount") {
          return (
            <Grid item container>
              <Grid item md={5}>
                {/* {transformString(key).replace(" count", "")} */}
                {key === "maleCount"
                  ? "Male Users"
                  : key === "femaleCount"
                  ? "Female Users"
                  : key === "lgbtaCount"
                  ? "LGBTA Users"
                  : key === "activeUserCount"
                  ? "Active Users"
                  : key === "disactiveUserCount"
                  ? "Non Active Users"
                  : key === "subscrbeUserCount"
                  ? "Subscribed Users"
                  : key === "unsubscrbeUserCount"
                  ? "Non Subscribed Users"
                  : ""}
              </Grid>
              <Grid item md={5}>
                <SparkLineChart
                  data={value} //[1, 4, 2, 5, 7, 2, 4, 6]
                  height={30}
                  sx={{
                    "& .MuiLineElement-root": {
                      stroke: lineChartColors[index] ?? "red",
                    },
                  }}
                />
              </Grid>
              <Grid item md={2}>
                {value.reduce((acc, currentValue) => acc + currentValue, 0)}
              </Grid>
            </Grid>
          );
        }
      })}
    </>
  );
};

export const elementPlaceHolders = (type) => {
  switch (type) {
    case 1: // portfolio cover image
      return (
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{
            width: "244px",
            maxWidth: "100%",
            height: "204px",
            borderRadius: "1rem",
            aspectRatio: 16 / 16,
          }}
        />
      );
      break;
    case 2: // artists cards
      return (
        <Grid container item spacing={2}>
          {[1, 2, 3, 4, 5].map((items) => (
            <Grid item xs={6} sm={4} md={3} lg={2.4} key={items}>
              <ArtistsCardSkeleton />
            </Grid>
          ))}
        </Grid>
      );
      break;
    case 3: // my requests casting call
      return (
        <Grid item container spacing={2}>
          {[1, 2, 3, 4].map((items) => (
            <Grid md={3} sm={4} xs={12} item key={items}>
              <CastingCallRequestCard />
            </Grid>
          ))}
        </Grid>
      );
      break;
    case 4: // fav cards
      return (
        <Grid container item spacing={2}>
          {[1, 2, 3, 4].map((items) => (
            <Grid item md={3} sm={4} xs={12} key={items}>
              <ArtistsCardSkeleton />
            </Grid>
          ))}
        </Grid>
      );
      break;
    case 5: // Reviews
      return (
        <Grid container item spacing={2}>
          {[1, 2, 3, 4].map((item) => (
            <ReviewsSkeleton key={item} />
          ))}
        </Grid>
      );
      break;
    case 6: // pricing plan
      return (
        <Grid container item spacing={2}>
          {[1, 2, 3].map((items) => (
            <Grid item md={3} sm={4} xs={12} key={items}>
              <PricingCardSkeleton />
            </Grid>
          ))}
        </Grid>
      );
      break;
    case 7: // service cards
      return (
        <Grid container item spacing={2}>
          {[1, 2, 3, 4].map((items) => (
            <Grid item md={3} sm={4} xs={12} key={items}>
              <ServiceCardSkeleton />
            </Grid>
          ))}
        </Grid>
      );
      break;
    case 8: // find work
      return (
        <Grid container mt={5} spacing={2}>
          {[1, 2, 3].map((items) => (
            <Grid key={items} item xl={3} md={4} sm={6} xs={12}>
              <FindWorkCardSkeleton />
            </Grid>
          ))}
        </Grid>
      );
      break;
    case 9: // casting calls
      return (
        <Grid container mt={2} spacing={2}>
          {[1, 2, 3, 4].map((items) => (
            <Grid key={items} lg={3} md={4} sm={6} xs={12} item>
              <CastingcallCardSkeleton />
            </Grid>
          ))}
        </Grid>
      );
      break;
    case 10: // competitions
      return (
        <Grid container mt={2} spacing={2}>
          {[1, 2, 3, 4].map((items) => (
            <Grid key={items} lg={3} md={4} sm={6} xs={12} item>
              <CompetitionCardSkeleton />
            </Grid>
          ))}
        </Grid>
      );
      break;
    default:
      return null;
  }
};

export const customEnqueueSnackbar = (message, options = {}) => {
  return enqueueSnackbar(message, {
    ...options,
    content: (key) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "9px",
          borderRadius: "0.5rem",
          minWidth: { xs: "initial", sm: "350px" },
          maxWidth: "100%",
          ...variantColors[options?.variant ?? "warning"],
        }}
      >
        <Box sx={{ marginRight: 1 }}>
          {variantIcons[options?.variant] || variantIcons.warning}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            padding: "0rem 0.2rem",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", fontSize: "1rem" }}
          >
            {variantTitles[options?.variant] || "Info"}
          </Typography>
          <Typography variant="body2">{message}</Typography>
        </Box>
        <IconButton
          sx={{ color: "inherit", padding: "0px" }}
          onClick={() => closeSnackbar(key)}
        >
          <Close />
        </IconButton>
      </Box>
    ),
  });
};
