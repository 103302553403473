import { Dialog, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Customizedinput from "@components/Customizedinput";
import Customizedselect from "@components/Customizedselect";
import Customizeddate from "@components/Customizeddate";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { getdropdowns_list } from "@services/Userservices";
import { api_dateformating } from "@helpers/Helperfunctions";
import { emptystringcheck } from "@validations/Commonvalidations";
import { useSelector } from "react-redux";
import { create_estimation_project } from "@services/Userservices";
import Customizedbutton from "@components/Customizedbutton";
import CancelIcon from "@mui/icons-material/Cancel";
import { Portfolio_Styles } from "@styles/Dashboardstyles";

const Createestimationproject = (props) => {
  const { open, close } = props;
  const JSX_styles = Portfolio_Styles();
  const user_data = useSelector((state) => state.authentication.data);
  const [project_details, setProject_details] = useState({
    projectname: "",
    fromDate: "",
    toDate: "",
    project_type: {},
  });
  const [allprojectypes, setAllprojecttypes] = useState([]);

  const handleinputchange = (e) => {
    const { name, value } = e.target;
    setProject_details({ ...project_details, [name]: value });
  };

  /**
   * Getting all project types on initial load
   */
  const getall_projecttypes = async () => {
    try {
      const projects_res = await getdropdowns_list({ type: 14 });
      if (projects_res?.status) {
        setAllprojecttypes(projects_res?.data);
      } else {
        customEnqueueSnackbar(projects_res?.message, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Creating the new estimation project
   * @returns
   */
  const handlecreate_project = async () => {
    try {
      if (emptystringcheck(project_details?.projectname, "Project name"))
        return;
      if (Object.keys(project_details?.project_type).length === 0) {
        customEnqueueSnackbar("Please select the project type.", {
          variant: "warning",
        });
        return;
      } else if (project_details?.fromDate === "") {
        customEnqueueSnackbar("Please select the from date.", {
          variant: "warning",
        });
        return;
      } else if (project_details?.toDate === "") {
        customEnqueueSnackbar("Please select the to date.", {
          variant: "warning",
        });
        return;
      } else {
        const req = {
          userId: user_data?.id,
          projectName: project_details?.projectname,
          fromDate: api_dateformating(project_details?.fromDate),
          toDate: api_dateformating(project_details?.toDate),
          projectTypeId: project_details?.project_type?.id,
        };
        const create_project = await create_estimation_project(req);
        if (create_project?.status) {
          setProject_details({
            projectname: "",
            fromDate: "",
            toDate: "",
            project_type: {},
          });
          customEnqueueSnackbar(create_project?.message, {
            variant: "success",
          });
          close(1);
        } else {
          customEnqueueSnackbar(create_project?.message, { variant: "error" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleprojecttype = (data) => {
    setProject_details((prev) => ({ ...prev, project_type: data }));
  };

  useEffect(() => {
    if (open) {
      getall_projecttypes();
    }
  }, [props]);
  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth={"xs"}
      PaperProps={{
        sx: {
          // m: 0,
          // width: 420,
          // maxWidth: 420,
          borderRadius: "1rem",
          "::-webkit-scrollbar": { display: "none" },
        },
      }}
    >
      <DialogTitle
        textAlign={"right"}
        sx={{ padding: "10px", paddingBottom: "0px", height: "1.4rem" }}
      >
        {/* <HighlightOffIcon
          sx={{ color: "rgba(0, 0, 0, 0.6)" }}
          onClick={close}
        /> */}
        <CancelIcon className={JSX_styles.closeiconstyle} onClick={close} />
      </DialogTitle>
      <DialogContent mt={2} sx={{ "::-webkit-scrollbar": { display: "none" } }}>
        <Grid container direction="column" spacing={2}>
          <Grid item fontWeight={700}>
            Create New Project
          </Grid>
          <Grid item>
            <Stack spacing={1}>
              <Grid item>Project Name</Grid>
              <Grid item>
                <Customizedinput
                  placeholder="Enter here"
                  value={project_details?.projectname}
                  fullWidth
                  name="projectname"
                  onChange={(e) => handleinputchange(e)}
                />
              </Grid>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={1}>
              <Grid item>Project Type</Grid>
              <Grid item>
                <Customizedselect
                  type={2}
                  options={allprojectypes}
                  value={project_details?.project_type?.postRequestTypes}
                  display_name={"postRequestTypes"}
                  onChange={handleprojecttype}
                />
              </Grid>
            </Stack>
          </Grid>
          <Grid item container justifyContent={"space-between"} spacing={1}>
            <Grid item xs={12} fontWeight={700}>
              Duration of the Project
            </Grid>
            <Grid item md={5.5} xs={12}>
              <Stack spacing={1}>
                <Grid item>From Date</Grid>
                <Grid item>
                  <Customizeddate
                    value={project_details?.fromDate}
                    placeholder="Select From"
                    minDate={
                      new Date()
                      // project_details?.toDate === ""
                      //   ? new Date()
                      //   : project_details?.toDate
                    }
                    onChange={(newValue) => {
                      if (newValue === null || newValue === "") {
                        setProject_details((prev) => ({
                          ...prev,
                          fromDate: "",
                          toDate: "",
                        }));
                      } else {
                        setProject_details((prev) => ({
                          ...prev,
                          fromDate: newValue,
                          toDate: "",
                        }));
                      }
                    }}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={5.5} xs={12}>
              <Stack spacing={1}>
                <Grid item>To Date</Grid>
                <Grid item>
                  <Customizeddate
                    value={project_details?.toDate}
                    placeholder="Select To"
                    minDate={
                      project_details?.fromDate === ""
                        ? new Date()
                        : new Date(
                            project_details?.fromDate.getTime() +
                              1000 * 60 * 60 * 24
                          )
                    }
                    onChange={(newValue) => {
                      if (newValue === null || newValue === "") {
                        setProject_details((prev) => ({
                          ...prev,
                          toDate: "",
                        }));
                      } else {
                        setProject_details((prev) => ({
                          ...prev,
                          toDate: newValue,
                        }));
                      }
                    }}
                  />{" "}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Grid item container gap={2} justifyContent={"center"}>
            <Grid item md={"auto"}>
              <Customizedbutton
                data="Cancel"
                // swidth={"170px"}
                // sheight={"46px"}
                size={"xxl"}
                styles={{
                  fontSize: "18px",
                  fontWeight: "400",
                  borderRadius: "2rem",
                }}
                bgcolor={"#333333"}
                onClick={close}
              />
            </Grid>
            <Grid item md={"auto"}>
              <Customizedbutton
                data="Create"
                // swidth={"170px"}
                // sheight={"46px"}
                size={"xxl"}
                styles={{
                  fontSize: "18px",
                  fontWeight: "400",
                  borderRadius: "2rem",
                }}
                onClick={() => handlecreate_project()}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Createestimationproject;
