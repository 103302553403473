import "@/App.css";
import Router from "@routes/Router";
import axios from "@services/Yourmocaclient";
import { Envexports } from "@exports/Envexports";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Loadingprovider } from "@parts/Loadingprovider";
import { isAccessTokenExpired } from "@helpers/Helperfunctions";
import { refreshtokenApi } from "@services/Commonservices";
import { StorageClient } from "@helpers/Helperfunctions";
import { default_token_endpoints } from "@constants/Userconstants";
import { currency_endpoint_constants } from "@constants/Userconstants";
import { registered_currency_endpoints } from "@constants/Userconstants";
import CustomSelectsearchscroll from "./components/CustomSelectsearchscroll";

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif",
  },
});
function App() {
  const user_data = useSelector((state) => state.authentication.data);
  let refresh_token = StorageClient.getItem("refreshtoken");
  /**
   * Intercepting the request
   */
  axios.interceptors.request.use(async (request) => {
    try {
      let authorizationkey = Envexports?.defaultauthkey;
      let token = StorageClient.getItem("token");
      if (token !== null && !default_token_endpoints.includes(request.url)) {
        request.customData = {
          userId: user_data?.id,
          refreshToken: refresh_token,
        };
        if (isAccessTokenExpired()) {
          const refresh_req = {
            userId: user_data?.id,
            refreshToken: refresh_token,
          };
          let refresh = await refreshtokenApi(refresh_req);
          authorizationkey = refresh;
        } else {
          authorizationkey = token;
        }
      }
      /**Currency param  adding */
      if (currency_endpoint_constants.includes(request.url)) {
        /**While posting currency form users account it should be post same as user registered country
         * while retrieving data user can  retrieve in any currency where we can select in top header of the application
         */
        let user_currency = registered_currency_endpoints.includes(request.url)
          ? StorageClient.getItem("baseCurrency") ?? ""
          : StorageClient.getItem("currency") ?? "";
        // user_data?.isAdmin
        // ? "INR"
        // :
        /**if content type is form data then we will add the currency in formdata */
        if (
          request.headers["Content-Type"] === "multipart/formdata" ||
          request.headers["Content-Type"] === "multipart/form-data"
        ) {
          // Checking for currency param exist or not if not then we are appending it
          if (!request.data.has("currency")) {
            const formData = request.data || new FormData();
            formData.append("currency", user_currency);
            request.data = formData;
          }
        } else {
          request.data = {
            ...request.data,
            currency: user_currency,
          };
        }
      }
      request.headers["Authorization"] = authorizationkey;
      request._userId = user_data?.id;
      return request;
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  });

  // interceptors response
  axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      const { status } = error.response;
      if (status === 401) {
        // Access the data from the custom property of the request config

        try {
          StorageClient.clear();
          localStorage.removeItem("persist:root"); // Replace 'root' with your key
          window.location.replace("/");
        } catch (refreshError) {
          // Handle refresh error, e.g., redirect to login page
          StorageClient.clear();
          localStorage.removeItem("persist:root"); // Replace 'root' with your key
          window.location.replace("/");
          return Promise.reject(refreshError);
        }
      } else {
        return error;
      }
    }
  );

  return (
    // <GoogleOAuthProvider clientId={Envexports.googleauthkey}>
    <ThemeProvider theme={theme}>
      <Loadingprovider>
        <Router />
      </Loadingprovider>
    </ThemeProvider>
    // </GoogleOAuthProvider>
  );
}

export default App;
