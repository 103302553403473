import pptx from "@images/pptx.svg";
import mp4 from "@images/mp4.svg";
import mov from "@images/mov.svg";
import png from "@images/png.svg";
import jpeg from "@images/jpeg.svg";
import xls from "@images/xls.svg";
import ppt from "@images/ppt.svg";
import jpg from "@images/jpg.svg";
import docx from "@images/docx.svg";
import xlsx from "@images/xlsx.svg";
import doc from "@images/doc.svg";
import pdficon from "@images/pdficon.svg";
import CommonImage from "@components/CommonImage";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Artists from "@images/Artists.svg";
import Crew from "@images/Crew.svg";
import Audio from "@images/Audio.svg";
import Producers from "@images/producer.svg";
import Actor from "@images/Actor.svg";
import Actress from "@images/Actress.svg";
import MaleModel from "@images/MaleModel.svg";
import FemaleModel from "@images/FemaleModel.svg";
import ChildArtist from "@images/ChildArtist.svg";
import Influencers from "@images/Influencers.svg";
import ExecutiveProducer from "@images/ExecutiveProducer.svg";
import CoProducer from "@images/CoProducer.svg";
import LineProducer from "@images/LineProducer.svg";
import AssociateProducer from "@images/AssociateProducer.svg";
import Caravan from "@images/Caravan.svg";
import Location from "@images/location.svg";
import Director from "@images/Director.svg";
import photography from "@images/photography.svg";
import Editor from "@images/Editor.svg";
import SpotEditor from "@images/SpotEditor.svg";
import Coloriest from "@images/Coloriest.svg";
import Focuspuller from "@assets/images/Focuspuller.svg";
import Vfx from "@assets/images/Vfx.svg";
import ScriptWriter from "@images/ScriptWriter.svg";
import StoryBoard from "@images/StoryBoard.svg";
import Photographer from "@images/Photographer.svg";
import CameraAssistant from "@images/CameraAssistant.svg";
import AssistantDirector from "@images/AssistantDirector.svg";
import CastingDirector from "@images/CastingDirector.svg";
import ContentWriters from "@images/ContentWriters.svg";
import ArtDirector from "@images/ArtDirector.svg";
import MusicDirector from "@images/MusicDirector.svg";
import SyncSound from "@images/SyncSound.svg";
import SoundDesign from "@images/SoundDesign.svg";
import Foley from "@images/Foley.svg";
import Aerialcinematographer from "@images/Aerialcinematographer.svg";
import Makeup from "@images/Makeup.svg";
import Costume from "@images/Costume.svg";
import Voiceover from "@images/Voiceover1.svg";
import DanceChoreographer from "@images/DanceChoreographer.svg";
import ActionChoreographer from "@images/ActionChoreographer.svg";
import Producer from "@assets/images/Producer1.svg";
import FinancierInvestor from "@images/FinancierInvestor.svg";
import MoviePromoter from "@images/MoviePromoter.svg";
import DigitalMarketing from "@images/DigitalMarketing.svg";
import LocationManager from "@images/LocationManager.svg";
import executive_producer from "@images/executive_producer.svg";
import co_producer from "@images/co_producer.svg";
import producerline from "@images/producerline.svg";
import producerassociate from "@images/producerassociate.svg";
import producercreative from "@images/producercreative.svg";
import productioncontroller from "@images/productioncontroller.svg";
// import Photographer from "@images/Photographer.svg";
import Videographer from "@images/Videographer.svg";
// import Makeup from "@images/Makeup.svg";
// import Costume from "@images/Costume.svg";
import EventManagement from "@images/EventManagement.svg";

/**Complete profile initial state */
export const complete_profile_val = {
  firstName: "",
  lastName: "",
  displayName: "",
  age: "",
  gender: "",
  phone_num: "",
  c_person_name: "",
  watsapp_num: "",
  email: "",
  city: "",
  state: {},
  country: {},
  description: "",
  skills: [],
  company_name: "",
  displayName: "",
  height: "",
  weight: "",
  awards: "",
  from_date: "",
  to_date: "",
  other_projects: "",
  sftwr_prof: "",
  projects_type: "",
  services_oferd: "",
  revisons: "",
  vimeo: "",
  youtube: "",
  instagram: "",
  facebook: "",
  services: "",
  staff_num: "",
  chefs_qual: [], //"",
  logistic_details: "",
  serving_style: [], //"",
  service_types: "",
  serviceofferedMess: [],
  vehicle_types: "",
  caravan_types: [],
  studioType: [],
  event_types: "",
  prev_events: "",
  types_oflenses: "",
  photography_equip: "",
  cam_models: "",
  musical_instruments: "",
  audio_equipment: "",
  drone_models: "",
  aireal_cinematorgraphy_Style: "",
  cinematography_commercial_license: "",
  makeup_prodcuts: "",
  cloth_brands: "",
  voiceover_languages: [
    {
      id: 0,
      name: "",
      SubcategoryId: 40,
      images: null,
      colorCode: "#FFE7E2",
    },
  ],
  experience: "",
  expirience_years: "",
  skintype: "",
  responsetime: {},
  phoneNumberStatus: 0,
  whatsAppNumberStatus: 0,
  instagramCount: 0,
  fbCount: 0,
  servicesOfferedForDigitalMarketing: [],
  websiteAndOnlinePlatforms: "",
  reviewsAndTestimonials: "",
  partnerships: "",
  locationType: [],
  client_portfolio: "",
};
export const response_time = [
  { name: "12 hours", id: 1 },
  { name: "24 hours", id: 2 },
  { name: "48 hours", id: 3 },
];
/**
 * Complete profile pdfs initial state values
 */
export const complete_profile_pdfs = {
  aboutme: null,
  guidelines: null,
  aboutus: null,
  our_journey: null,
  equipments_list: null,
  our_policy: null,
  voiceover_demo: [{ audioFile: null, language: "" }],
  demoTracks: [{ audioFile: null, language: "" }],
  isAboutMePdfDeleted: 0,
  isGuidelinesPdfDeleted: 0,
  isOurJourneyPdfDeleted: 0,
  isequipmentPdfDeleted: 0,
  ispolicyPdfDeleted: 0,
  isvoiceOverDemosPdfDeleted: [],//0,
  isaboutUsPdfDeleted: 0,
  isdemoTracksDeleted: [],//0,
};
/**Default india country object */
export const defaultcountry_code = {
  id: 101,
  countryName: "India",
  countryCode: "IN",
  dialing_code: "+91",
  currency: "INR",
  emoji: "🇮🇳",
  currencySymbol: "₹",
  flag: "in.png",
};

/**
 * Subcategoryprofile component  initial drop_downs state
 */
export const dropdown_state = {
  ages: [],
  skintypes: [],
  heights: [],
  budgets: [],
  weights: [],
  countries: [],
  states: [],
  brandnames: [],
  food_types: [],
  vehicle_types: [],
  caravan_types: [],
  event_types: [],
  equipmenttypes: [],
  transport_services: [],
  food_service_type: [],
  allStudio: [],
};
/**Subcategory profiles filter initialstates */
export const profiles_filter_states = {
  country: {},
  state: {},
  budget: {},
  height: {},
  weight: {},
  skintone: {},
  age: {},
  sortby: {},
  brandname: {},
  service_type: {},
  food_service_type: {},
  vehicle_type: {},
  caravan_type: {},
  event_type: {},
  equipment_type: {},
  experience: "",
  transport_type: {},
  studio: {},
};
/**Filter dropdowns states constants in subcategory profiles components */
export const dropdownMapping = {
  15: "heights",
  16: "skintypes",
  17: "weights",
  18: "budgets",
  20: "ages",
  7: "brandnames",
  10: "food_types",
  11: "food_service_type",
  5: "vehicle_types",
  4: "transport_types",
  9: "caravan_types",
  2: "event_types",
  28: "allStudio",
};
/**Filters dropdowns api request values constants */
export const subcategoryDropdownMap = {
  1: [15, 16, 17, 18, 20],
  2: [15, 16, 17, 18, 20],
  3: [15, 16, 17, 18, 20],
  4: [15, 16, 17, 18, 20],
  5: [15, 16, 17, 18, 20],
  6: [15, 16, 17, 18, 20],
  8: [28],
  // 7: [7],
  9: [18],
  10: [10, 11, 18],
  11: [4, 5],
  12: [9],
  17: [2],
};
/**File formates of having different images */

export const file_formate_display = {
  pptx: <CommonImage src={pptx} alt="pptx" />,
  mp4: <CommonImage src={mp4} alt="mp4 " />,
  mov: <CommonImage src={mov} alt="mov " />,
  png: <CommonImage src={png} alt="png " />,
  jpeg: <CommonImage src={jpeg} alt="jpeg" />,
  xls: <CommonImage src={xls} alt="xls " />,
  ppt: <CommonImage src={ppt} alt="ppt " />,
  jpg: <CommonImage src={jpg} alt="jpg " />,
  docx: <CommonImage src={docx} alt="docx" />,
  xlsx: <CommonImage src={xlsx} alt="xlsx" />,
  doc: <CommonImage src={doc} alt="doc " />,
  pdf: <CommonImage src={pdficon} alt="pdficon " />,
};
export const default_token_endpoints = [
  "/login",
  "/signUp",
  "/adminVerifyOtp",
  "/adminResetPassword",
  "/forgotPassword",
  "/verifyForgotPasswordOTP",
  "/checkEmailVerificationStatus",
  "/sendVerificationsToRestore",
  "/verifyMobileOTP",
  "/resetPassword",
  "/socialLogin",
  "/adminLogin",
  "/getProfiles",
  "/searchSuggestions",
  "/getCategoriesWithSubcategory",
  "/getCastingCalls",
  "/getAllCategories",
  "/resendOtpOrEmail",
  "/subcategories/:categoryId",
  "/subcategories/1",
  "/subcategories/2",
  "/subcategories/3",
  "/subcategories/4",
  "/getAllCountryCode",
  "/getStates",
  "/refreshAccessToken",
  "/getSuperSubCategories",
  "/getDropdownList",
  "/adminGetAllCompetitions",
  "/getAllPostRequests",
  "/getDropdownListCastingCall",
  "/searchWork",
  "/getPopularProfiles",
  "/getPopularSearch",
  "/adminForgotPassword",
  "/getVideo",
  "/searchProfilesOrServices",
  "/guestUserSubscriptions",
  "/getEquipmentBrands",
  "/getEquipmentList",
  "/contactUs",
  "/getAdminHomeBanner",
  "/adminGetAllPressAndNews",
  "/adminGetContentManagement",
  "/adminGetAllFaq",
  "/getClientTestimonial",
  "/getHowitWorks",
  "/getAboutUs",
  "/androidDeleteAccount",
  "/otpVerificationForDeleteAccount",
  "/allSubCategoryList",
];

export const currency_endpoint_constants = [
  "/addEquipment",
  "/editEquipment",
  "/addOrEditMess",
  "/addOrEditLocation",
  "/addOrEditPostWork",
  "/addOrEditTransportation",
  "/addOrEditCaravan",
  "/getProfiles",
  "/searchWork",
  "/getDetailedEstimationServices",
  "/getMyRequests",
  "/getPopularProfiles",
  "/addOrEditPricingPlan",
  "/getDetailedEstimationServices",
  "/getfavoritesDetails",
  "/addMemberToEstimationList",
  "/updateEstimationRateInfo",
  "/addOrEditeventManagement",
  "/getMyEstimationList",
  "/getProjectEstimationsById",
  "/getProjectEstimationOverview",
  "/getMyProjects",
  "/getdetailedestimationoverview",
  "/searchProfilesOrServices",
  "/updateSubscriptionDetails",
  "/getUserDetails",
  "/orders",
  "/getpackagesOtherProfiles",
];
export const registered_currency_endpoints = [
  "/addOrEditPricingPlan",
  "/updateSubscriptionDetails",
  "/addEquipment",
  "/editEquipment",
  "/addOrEditMess",
  "/addOrEditLocation",
  "/addOrEditPostWork",
  "/addOrEditTransportation",
  "/addOrEditCaravan",
  "/addMemberToEstimationList",
  "/addOrEditeventManagement",
  "/orders",
  "/updateEstimationRateInfo",
];
export const privilegesConfig = [
  { label: "User Management", url: "/admin/usermanagement", value: 1 },
  {
    label: "Equipment Management",
    url: "/admin/equipmentmanagement",
    value: 3,
  },
  {
    label: "Casting Call Competitions",
    url: "/admin/castingcalls",
    value: 5,
  },
  { label: "Messages", url: "/admin/messages", value: 6 },
  { label: "Reports", url: "/admin/reports", value: 7 },
  { label: "Content Management", url: "/admin/contentmanagement", value: 8 },
];

export const mocaHeaderNavOptions = [
  { label: "Home", url: "/home" },
  { label: "All Categories", url: "/allcategories" },
  { label: "Post a Request", url: "/postrequest" },
  { label: "Find Work", url: "/findwork" },
  { label: "Casting Calls", url: "/castingcalls" },
  { label: "Pricing", url: "/pricing" },
  { label: "Contact Us", url: "/contactus" },
];
export const FAVORITE_TYPES = {
  9: 2,
  10: 6,
  11: 7,
  12: 8,
  17: 9,
  7: 10,
};
export const search_resultkeys = {
  0: "profiles",
  7: "equipments",
  9: "locations",
  10: "mess",
  11: "transportation",
  12: "caravan",
  17: "events",
};
export const display_tags = {
  7: "Equipment",
  9: "Location",
  10: "Mess",
  11: "Transportation",
  12: "Caravan",
  17: "Event management",
};
export const user_plans_creation = {
  Basic: [
    { benefit: "Work time Up to 4 hours" },
    { benefit: " No makeup included" },
    { benefit: " No costumes provided" },
    { benefit: " No Instagram collaboration post" },
    { benefit: " No Instagram stories included" },
    { benefit: " No Instagram post included" },
    { benefit: " No videographer provided" },
    { benefit: " No photographer provided" },
    { benefit: " No editing services included" },
  ],
  Standard: [
    { benefit: "Work time Up to 6 hours" },
    { benefit: "Basic makeup included" },
    { benefit: "Basic costumes provided (limited selection)" },
    { benefit: "1 Instagram collaboration post included" },
    { benefit: "2 Instagram stories included" },
    { benefit: "1 Instagram post included" },
    { benefit: "No videographer provided" },
    { benefit: "No photographer provided" },
    { benefit: "Basic editing services included" },
  ],
  Premium: [
    { benefit: "Work time Up to 10 hours" },
    { benefit: "Professional makeup included" },
    { benefit: "Custom costumes provided" },
    { benefit: "2 Instagram collaboration posts included" },
    { benefit: "4 Instagram stories included" },
    { benefit: "2 Instagram posts included" },
    { benefit: "Professional videographer included" },
    { benefit: "Professional photographer included" },
    { benefit: "Advanced editing services included" },
  ],
};
export const castingcall_acceptmsg = ({ sendername, recepientname }) => {
  return `Dear ${recepientname},\n\nCongratulations! We are thrilled to inform you that you have been selected for the casting opportunity with our project.Your skills, experience, and enthusiasm stood out among the applicants, and we believe you will make a valuable addition to our team.We will be in touch shortly with further details regarding next steps.\n\nWelcome aboard, and thank you for choosing to be a part of our project.\n\nBest regards,\n${sendername}`;
};

export const castingcall_rejectmsg = ({ sendername, recepientname }) => {
  return `Dear ${recepientname},\n
Thank you for taking the time to apply for the casting opportunity with us. 
We truly appreciate your interest and the effort you put into your application. 
After careful consideration, we have decided to move forward with other candidates whose qualifications better match our current needs.
We encourage you to keep pursuing your passions and wish you all the best in your future endeavors.\n
Sincerely,
${sendername}`;
};
export const competition_acceptmsg = ({
  sendername,
  recepientname,
  category = "",
}) => {
  return `Dear ${recepientname},\n\nCongratulations! Your submission for the ${
    category ?? ""
  } competition has been accepted. We're impressed by your vision and storytelling and look forward to showcasing your film at our event. Stay tuned for further details.\n\nBest regards,\n${sendername}`;
};
export const competition_declinemsg = ({
  sendername,
  recepientname,
  category = "",
}) => {
  return `Dear ${recepientname},\n\nThank you for your submission to our ${
    category ?? ""
  } competition. While we received many impressive entries, we regret to inform you that your project was not selected this year. Please continue pursuing your passion, and we hope to see more of your work in the future.\n\nBest regards,\n${sendername}`;
};
export const decline_prefillresons = {
  Meeting:
    "I regret to inform you that I won't be able to attend the meeting as I have another meeting scheduled at the same time. Could we possibly catch up during our next meeting?",
};
// Hello and welcome to the YourMoca family!  \n
export const AdminWelcomeMessage = `Welcome to YourMoca Support!  \n
We're thrilled to have you with us, where creativity knows no bounds. Whether you're just getting started or diving deeper into your creative journey, we're here to ensure your journey is smooth and inspiring.  \n
Got a question? No worries! Our team is always ready to help, and we’ll be reaching out to you soon. feel free to explore our [Help Center URL link], or if something’s on your mind right now, shoot us an email at support@yourmoca.com.  \n
We can’t wait to assist you on this exciting adventure!  \n
Warmest regards,
The YourMoca Support Team
`;

// `
// Welcome to YourMoca Support! \n
// Hi there,
// Thank you for reaching out to the YourMoca support team! We’re here to assist you and ensure you have a seamless experience with us.\n
// Your inquiry is important to us, and a member of our team will get back to you shortly. In the meantime, feel free to explore our Help Center or reach out to us directly at support@yourmoca.com if you have any immediate questions.\n
// We look forward to assisting you!\n
// Best regards,
// The YourMoca Support Team
// `;

export const requestQuotemsg = ({ sendername, recepientname }) => {
  return `Hello${
    recepientname ? " " + recepientname : ""
  },\n\nI have reviewed your profile and am impressed by your expertise. We would be pleased to invite you to join our team.\n\nPlease find the details of our budget and project timelines below. Let me know your availability for further discussion.\n\nBest regards,\n${sendername}`;
};

export const caravanInitialValues = {
  typesOfCaravan: "",
  sizeofCapacity: "",
  title: "",
  facilitiesInside: "",
  equipmentAmenities: "",
  bookingFrom: "",
  bookingTo: "",
  pricingModel: "",
  price: "",
  name: "",
  drivingLicenseNumber: "",
  languageSpoken: [{ name: "" }], //"",
  photoOrVideoFile: null,
  mediaType: "",
};
export const transportationInitialValues = {
  title: "",
  serviceType: "",
  vehicleType: "",
  seatingSpecifications: "",
  vehicleOccupancy: "",
  bookingFrom: "",
  bookingTo: "",
  pricingModel: "",
  price: "",
  driverName: "",
  drivingLicenseNumber: "",
  languageSpoken: [{ name: "" }], //"",
  photoOrVideoFile: null,
  mediaType: "",
  occupancy: "",
};

export const propertyInitialValues = {
  photoOrVideoFile: null,
  title: "",
  category: "",
  location: "",
  description: "",
  document: null,
  state: {},
  country: {},
  city: "",
  pricingModel: {},
  fromDate: "",
  toDate: "",
  price: "",
  keyFeaturesAtrributes: "",
  accessibilityInformation: "",
  permitsAndRegulations: "",
  permitRequirements: "",
  anyRestrictionsForFilming: "",
  localRegulationsOrRequirements: "",
  sizeOrAreaOfLocation: {},
};

export const castingcallInitialValues = {
  project_type: {},
  genre: {},
  project_name: "",
  castingcall_title: "",
  director: "",
  production_company: "",
  audition_date: "",
  attachDocuments: [],
  is_admin: "",
  important_notes: "",
  synopsis: "",
  deletedmedia_ids: [],
  country: {},
  state: {},
  city: "",
  endDate: "",
};

export const registrationInitialValues = {
  firstname: "",
  lastname: "",
  c_name: "",
  displayname: "",
  phn_num: "",
  email: "",
  pwd: "",
  cnfmpwd: "",
  socialid: "",
  pwdstatus: false,
  cnfmpwd_status: false,
  country: {},
};
export const filelimitsmb = {
  image: 50,
  video: 500, //200,
};
export const filelimits = {
  imagelimit: filelimitsmb.image * 1024 * 1024,
  videolimit: filelimitsmb.video * 1024 * 1024,
};

export const snackbarmessages = {
  filesizeexceedsmsg: `Some of the selected files exceeded the maximum allowed size: images must be under ${filelimitsmb.image} MB and videos must be under ${filelimitsmb.video} MB.`,
};

export const variantIcons = {
  success: <CheckCircleOutlineOutlinedIcon color="#100d0b" />,
  error: <HighlightOffOutlinedIcon color="#100d0b" />,
  warning: <InfoOutlinedIcon color="#100d0b" />,
  info: <InfoOutlinedIcon color="#100d0b" />,
};

export const variantTitles = {
  success: "Success",
  error: "Error",
  warning: "Warning",
  info: "Information",
};

export const variantColors = {
  success: {
    backgroundColor: "#b7f7c3",
    color: "#100d0b",
  },
  error: {
    backgroundColor: "#feb7b5",
    color: "#100d0b",
  },
  warning: {
    backgroundColor: "#fbe7c6",
    color: "#100d0b",
  },
  info: {
    backgroundColor: "#b2e6f4",
    color: "#100d0b",
  },
};

export const allCategoriesIcons = {
  1: {
    backgroundColor: "#FFF5CD",
    icon: Artists,
  },
  2: {
    backgroundColor: "#FFE8D8",
    icon: Audio,
  },
  3: {
    backgroundColor: "#D9FAF8",
    icon: Producers,
  },
  4: {
    backgroundColor: "#D8F6FF",
    icon: Crew,
  },
};

export const allSubCategoryIcons = {
  1: { backgroundColor: "#ECFAFF", icon: Actor },
  2: { backgroundColor: "#FFF9EA", icon: Actress },
  3: { backgroundColor: "#FFEFEC", icon: MaleModel },
  4: { backgroundColor: "#ECFFFF", icon: FemaleModel },
  5: { backgroundColor: "#FDFFEA", icon: ChildArtist },
  6: { backgroundColor: "#FFF9EC", icon: Influencers },
  7: { backgroundColor: "#EAEAFF", icon: LineProducer },
  8: { backgroundColor: "#FFEFEC", icon: AssociateProducer },
  9: { backgroundColor: "#EAF2FF", icon: Location },
  10: { backgroundColor: "#FFF1E8", icon: ExecutiveProducer },
  11: { backgroundColor: "#EAFFF3", icon: CoProducer },
  12: { backgroundColor: "#E5F9FF", icon: Caravan },
  13: { backgroundColor: "#ECFFF6", icon: Photographer },
  14: { backgroundColor: "#EAF5FF", icon: Videographer },
  15: { backgroundColor: "#FFF0F2", icon: Makeup },
  16: { backgroundColor: "#ECFAFF", icon: Costume },
  17: { backgroundColor: "#FFFAED", icon: EventManagement },
  18: { backgroundColor: "#FFF7EB", icon: Director },
  19: { backgroundColor: "#FFEFEC", icon: photography },
  20: { backgroundColor: "#EBFAFF", icon: Editor },
  21: { backgroundColor: "#FFF1EB", icon: SpotEditor },
  22: { backgroundColor: "#EBFFF3", icon: Coloriest },
  23: { backgroundColor: "#FFF5EB", icon: Focuspuller },
  24: { backgroundColor: "#EDF4FF", icon: Vfx },
  25: { backgroundColor: "#FFF1EB", icon: ScriptWriter },
  26: { backgroundColor: "#FFF1F9", icon: StoryBoard },
  27: { backgroundColor: "#EBFFF5", icon: Photographer },
  28: { backgroundColor: "#FFEBF8", icon: CameraAssistant },
  29: { backgroundColor: "#EEEDFF", icon: AssistantDirector },
  30: { backgroundColor: "#FFFAEB", icon: CastingDirector },
  31: { backgroundColor: "#E9FFFD", icon: ContentWriters },
  32: { backgroundColor: "#FFEFF7", icon: ArtDirector },
  33: { backgroundColor: "#FFEBF4", icon: MusicDirector },
  34: { backgroundColor: "#FFF6EB", icon: SyncSound },
  35: { backgroundColor: "#E7FFFD", icon: SoundDesign },
  36: { backgroundColor: "#EBEBFF", icon: Foley },
  37: { backgroundColor: "#EDF6FF", icon: Aerialcinematographer },
  38: { backgroundColor: "#FFEFF1", icon: Makeup },
  39: { backgroundColor: "#E2F8FF", icon: Costume },
  40: { backgroundColor: "#FFF8E9", icon: Voiceover },
  41: { backgroundColor: "#FFEFEB", icon: DanceChoreographer },
  42: { backgroundColor: "#EDFAFF", icon: ActionChoreographer },
  43: { backgroundColor: "#E7FFF1", icon: Producer },
  44: { backgroundColor: "#FFF4EB", icon: executive_producer },
  45: { backgroundColor: "#F0F2FF", icon: co_producer },
  46: { backgroundColor: "#EEF9FF", icon: producerline },
  47: { backgroundColor: "#FFF2FA", icon: producerassociate },
  48: { backgroundColor: "#EEFBFF", icon: producercreative },
  49: { backgroundColor: "#FFF8E9", icon: productioncontroller },
  50: { backgroundColor: "#E7FFF1", icon: FinancierInvestor },
  51: { backgroundColor: "#FFF8E9", icon: MoviePromoter },
  52: { backgroundColor: "#F3EFFF", icon: DigitalMarketing },
  53: { backgroundColor: "#EBF3FF", icon: LocationManager },
};

export const popularProfilesEmpty = {
  artists: "No artist profiles have reached the top ratings yet!",
  vendors: "No vendor profiles have reached the top ratings yet!",
  weddingMakers: "No wedding filmmaker profiles have reached the top ratings yet!",
  crew: "No crew profiles have reached the top ratings yet!",
};
