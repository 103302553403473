/** @format */

import React, { Suspense } from "react";
import Signupprivateroutes from "./privateroutings/Signupprivateroutes";
import { Grid } from "@mui/material";
import Lottie from "lottie-react";
import mocaloaderAnimation from "../assets/loaders/mocaLoader_animation.json";
import Webviewroutes from "./Webviewroutes";
import { allowUsers } from "../helpers/Helperfunctions";
/**Lazy loading */
const Homeroutes = React.lazy(() => import("@routes/Homeroutes"));
const Allcategoryroutes = React.lazy(() => import("@routes/Allcategoryroutes"));
const Contactroutes = React.lazy(() => import("@routes/Contactroutes"));
const Findworkroutes = React.lazy(() => import("@routes/Findworkroutes"));
const Castingcallsroutes = React.lazy(() =>
  import("@routes/Castingcallsroutes")
);
const Postrequestroutes = React.lazy(() => import("@routes/Postrequestroutes"));
const Dashboardprivateroutes = React.lazy(() =>
  import("@privateroutes/Dashboardprivateroutes")
);
const Adminroutings = React.lazy(() => import("@adminroutes/Adminroutings"));
const Loginprivateroutes = React.lazy(() =>
  import("@privateroutes/Loginprivateroutes")
);
const Signupprivaterouting = React.lazy(() =>
  import("@privateroutes/Signupprivaterouting")
);
const Pricingroutes = React.lazy(() => import("@routes/Pricingroutes"));

const Router = () => {
  const isAllowedUser = allowUsers();
  if (isAllowedUser)
    return (
      <>
        <Suspense
          fallback={
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Lottie animationData={mocaloaderAnimation} />
            </Grid>
          }
        >
          {/* This is used for loginsignup page routing */}
          <Signupprivaterouting />
          {/* <Signupprivateroutes/> */}
          {/* <Loginsignuproutes/> */}
          {/* This is used for profile creation routes */}
          <Loginprivateroutes />
          <Homeroutes />
          <Pricingroutes />
          <Allcategoryroutes />
          <Contactroutes />
          <Findworkroutes />
          <Postrequestroutes />
          <Castingcallsroutes />
          <Dashboardprivateroutes />
          {/* admin routings */}
          <Adminroutings />
          <Webviewroutes />
        </Suspense>
      </>
    );
};

export default Router;
