export const Regex_exports = {
  emailRegex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  passwordRegex:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
  string_pattern: /[0-9~`!@#$%^&*()\-_=+\[\]{}\\|;:'",.<>\/?]/,
  mobile_regex: /^\d+$/,
  comma_seperated_regex: /^[a-zA-Z, ]*$/,
  extra_space: /\s+/g,
  numberRegex: /^[0-9]+$/,
  urlRegex: /\b(?:https?:\/\/|www\.)[\w-]+(?:\.[\w-]+)*(\/[^"'\s]*)?\b/g,
  urlRegexmust: /^https?:\/\/[\w-]+(?:\.[\w-]+)+(\/[^\s]*)?$/, // this will allows a link that includes a https || http // old regex /^https?:\/\/[\w-]+(?:\.[\w-]+)*(\/[^"'\s]*)?\b/g
  // /(?:https?:\/\/)?(?:www\.)?[\w-]+\.[\w]+(?:\.[\w]+)*/gi,
  youtubePattern : /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|shorts\/|live\/)|youtu\.be\/)([a-zA-Z0-9_-]+)/,
  // old youtube regex: /^(http(s)?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/)|youtu\.be\/)/,
  // instagramPattern: /(?:http(?:s)?:\/\/)?(?:www\.)?instagram\.com\/(?:[a-zA-Z0-9_\.]{1,30})\/?(?:\?.*)?$/,
  instagramPattern: /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/[a-zA-Z0-9_\.]{1,30}(?:\/[a-zA-Z0-9_\.]+)*(?:\/)?(?:\?.*)?$/,
  // /(?:http(?:s)?:\/\/)?(?:www\.)?instagram\.com\/(?!\?)(?:\w+\/)*\??(?:\?.*)?$/,
  // facebookPattern: /^(?:https?:\/\/)?(?:www\.)?(?:facebook|fb)\.com\/[A-Za-z0-9_.-]+\/?$/i, // /^(?:https?:\/\/)?(?:www\.)?(?:facebook|fb)\.com\/?$/i,
  facebookPattern: /^(?:https?:\/\/)?(?:www\.)?(?:facebook|fb)\.com\/[A-Za-z0-9_.-]+(?:\?.*)?$/i,
};
