import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { Regex_exports } from "@exports/Regex_eports";

const namechecking = (data, name) => {
  if (data.trim() === "") {
    customEnqueueSnackbar(
      `Oops! You forgot to enter your ${name}. Please provide it.`,
      { variant: "warning" }
    );
    return true;
  } else if (!data.trim()) {
    // This condition checks if the trimmed data is an empty string, which means it consists only of spaces
    customEnqueueSnackbar(
      `Invalid ${name}. Please provide a non-empty value.`,
      { variant: "warning" }
    );
    return true;
  } else if (Regex_exports.string_pattern.test(data)) {
    customEnqueueSnackbar(`${name} should be string`, { variant: "warning" });
    return true;
  } else {
    return false;
  }
};
const emptystringcheck = (data, name) => {
  if (data.trim() === "") {
    customEnqueueSnackbar(
      `Oops! You forgot to enter your ${name}. Please provide it.`,
      { variant: "warning" }
    );
    return true;
  }
};
/**
 *
 * @param {data} actualdata
 * @param {name} fieldname
 * @param {1} optional
 * @returns
 */
const emailchecking = (data, name) => {
  if (data.trim() === "" || !data.trim()) {
    customEnqueueSnackbar(`Please fill out ${name} field to proceed`, {
      variant: "warning",
    });
    return true;
  } else if (!Regex_exports.emailRegex.test(data)) {
    customEnqueueSnackbar(
      `Oops! It looks like the ${name} you entered is not valid. Please double-check it`,
      { variant: "warning" }
    );
    return true;
  } else {
    return false;
  }
};
const mobilechecking = (data, name) => {
  if (data.trim() === "" || !data.trim()) {
    customEnqueueSnackbar(`Please fill out ${name} field to proceed`, {
      variant: "warning",
    });
    return true;
  } else if (!Regex_exports.mobile_regex.test(data)) {
    customEnqueueSnackbar(
      `Oops! It looks like the ${name} you entered is not valid. Please double-check it
    `,
      { variant: "warning" }
    );
    return true;
  } else {
    return false;
  }
};
const passwordchecking = (data, name, type) => {
  let message = `Oops! It looks like the ${name} you entered is not valid. Please double-check it`;
  if (type === 1) {
    message =
      "Your password is weak. Please create a stronger one with at least 8 characters, including numbers, upper and lower case letters, and special characters.";
  }
  if (data.trim() === "" || !data.trim()) {
    customEnqueueSnackbar(
      `Oops! You forgot to enter your ${name}. Please provide it.
    `,
      { variant: "warning" }
    );
    return true;
  } else if (!Regex_exports.passwordRegex.test(data)) {
    customEnqueueSnackbar(message, { variant: "warning" });
    return true;
  } else {
    return false;
  }
};
const pwd_cnfmpwd_validation = (pwd, cnfmpwd) => {
  return pwd.trim() !== cnfmpwd.trim()
    ? (customEnqueueSnackbar("Password and confirm password should be equal", {
        variant: "warning",
      }),
      true)
    : false;
};
/**
 *
 * @param {Object} category category data we have
 * @param {Object} sub_category sub_category data we have
 * @param {Object} data inputfields data
 * @returns {Object} if the validation success return
 * validation param true or else returns false
 */
const complete_profilevalidation = (category, sub_category, data) => {
  try {
    // console.log(category, sub_category, data);

    let category_id = category?.id;
    let sub_category_id = sub_category?.id;
    let profile_details = data?.input_fields;
    let sel_languages = data?.sel_languages;
    let portfolio_links = data?.portfolio_links;
    let coverImage = data?.coverImage;
    /**
     * @param {email}- validation
     * checking for valid email formate
     */
    if (!coverImage) {
      customEnqueueSnackbar(`Please upload cover image`, {
        variant: "warning",
      });
      return { validated: false };
    } else if (profile_details?.displayName === "") {
      customEnqueueSnackbar(`Please enter display name`, {
        variant: "warning",
      });
      return { validated: false };
    } else if (
      profile_details?.email !== "" &&
      !Regex_exports.emailRegex.test(profile_details?.email)
    ) {
      customEnqueueSnackbar(
        `Oops! It looks like the Email you entered is not valid. Please double-check it`,
        { variant: "warning" }
      );
      return { validated: false };
    } else if (profile_details?.watsapp_num === "") {
      customEnqueueSnackbar(`Please enter whats'app number`, {
        variant: "warning",
      });
      return { validated: false };
    } else if (data?.sel_codes?.watsapp_num?.dialing_code === undefined) {
      customEnqueueSnackbar(`Please enter whats'app country code`, {
        variant: "warning",
      });
      return { validated: false };
    } else if (Object.keys(profile_details?.country).length === 0) {
      customEnqueueSnackbar(`Oops, Select the country`, { variant: "warning" });
      return { validated: false };
    } else if (Object?.keys(profile_details?.state).length === 0) {
      customEnqueueSnackbar(`Oops,Please select the state`, {
        variant: "warning",
      });
      return { validated: false };
    } else if (profile_details?.city === "") {
      customEnqueueSnackbar(`Oops,Please enter the city`, {
        variant: "warning",
      });
      return { validated: false };
    } else if (
      /**
       * from data and to date validation
       */
      profile_details?.from_date !== "" &&
      profile_details?.to_date === ""
    ) {
      customEnqueueSnackbar("Please fill out the To date", {
        variant: "warning",
      });
      return { validated: false };
    } else if (
      profile_details?.to_date !== "" &&
      profile_details?.from_date === ""
    ) {
      customEnqueueSnackbar("Please fill out the From date", {
        variant: "warning",
      });
      return { validated: false };
    }

    //  Languages validation
    //  if (
    //   [1].includes(category_id) ||
    //   [18, 19, 20, 21, 22].includes(sub_category_id)
    // ) {
    for (let index = 0; index <= sel_languages.length - 1; index++) {
      if (sel_languages[index]?.language === "") {
        customEnqueueSnackbar(
          `Please Select the language in the given fileds`,
          { variant: "warning" }
        );
        return { validated: false };
      } else if (sel_languages[index]?.proficiency === "") {
        customEnqueueSnackbar(
          `Please Select the proficiency in the given fields`,
          { variant: "warning" }
        );
        return { validated: false };
      }
    }
    // }

    //Portfolio links validations
    if (portfolio_links[0]?.link !== "") {
      for (let index = 0; index <= portfolio_links?.length - 1; index++) {
        if (
          portfolio_links[index]?.link === "" ||
          !Regex_exports.youtubePattern.test(portfolio_links[index]?.link)
        ) {
          customEnqueueSnackbar("Please provide a valid youtube video link", {
            variant: "warning",
          }); //Please fillout the valid youtube links you have.
          return { validated: false };
        }
      }
    }
    // social media validation
    if (
      data?.input_fields?.instagram &&
      !Regex_exports.instagramPattern.test(data?.input_fields?.instagram)
    ) {
      customEnqueueSnackbar("Please provide a valid instagram link", {
        variant: "warning",
      });
      return { validated: false };
    }
    if (
      data?.input_fields?.facebook &&
      !Regex_exports.facebookPattern.test(data?.input_fields?.facebook)
    ) {
      customEnqueueSnackbar("Please provide a valid facebook link", {
        variant: "warning",
      });
      return { validated: false };
    }
    return { validated: true };
  } catch (err) {
    console.log(err);
  }
};
/**
 * portfolio popups validation
 */
/**
 *
 * @param {string} value
 * @param {string} name
 * @param {integer} type 1->inputtextarea 2->select dropdowns
 * @returns boolean value
 */
const input_validation = (value, name, type) => {
  if (value === "" && type === 1) {
    customEnqueueSnackbar(`${name} should not be empty.`, {
      variant: "warning",
    });
    return false;
  } else if (value === "" && type === 2) {
    customEnqueueSnackbar(`select any of the value for ${name}.`, {
      variant: "warning",
    });
    return false;
  } else {
    return true;
  }
};
/**
 *
 * @param {mediafile} file
 * @param {string} name
 * @returns boolean value
 */
const mediafile_valdation = (file, name) => {
  if (file === null || file?.length === 0) {
    customEnqueueSnackbar(`Please upload ${name}.`, { variant: "warning" }); //upload any of the photo or video file for
    return false;
  } else {
    return true;
  }
};
const castingcall_requirementsvalidation = (carddata) => {
  if (carddata?.gender === "") {
    customEnqueueSnackbar("Please select the gender", { variant: "warning" });
    return true;
  } else if (carddata?.age === "") {
    customEnqueueSnackbar("Please Select age", { variant: "warning" });
    return true;
  } else if (Object.keys(carddata?.skinTone).length === 0) {
    customEnqueueSnackbar("Please select the skintone", { variant: "warning" });
    return true;
  } else if (Object.keys(carddata?.height).length === 0) {
    customEnqueueSnackbar("Please select height", { variant: "warning" });
    return true;
  } 
  // else if (Object.keys(carddata?.category).length === 0) {
  //   customEnqueueSnackbar("Please select category", { variant: "warning" });
  //   return true;
  // } 
  else if (Object.keys(carddata?.subCategory).length === 0) {
    customEnqueueSnackbar("Please select subcategory", { variant: "warning" });
    return true;
  } else if (carddata?.roleName === "") {
    customEnqueueSnackbar("Please Enter role for requirement", {
      variant: "warning",
    });
    return true;
  } else if (carddata?.roleDescription === "") {
    customEnqueueSnackbar("Please Enter description for requirement", {
      variant: "warning",
    });
    return true;
  } else {
    return false;
  }
};
export {
  namechecking,
  emptystringcheck,
  emailchecking,
  mobilechecking,
  passwordchecking,
  pwd_cnfmpwd_validation,
  complete_profilevalidation,
  input_validation,
  mediafile_valdation,
  castingcall_requirementsvalidation,
};
