import {
  Container,
  Grid,
  Stack,
  Button,
  Divider,
  InputAdornment,
} from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";
import signupbg from "@images/signupbg.png";
import registerbg from "@images/registerbgNew.png";
import social_mail from "@images/logos_google-gmail.svg";
import social_fb from "@images/logos_facebook.svg";
import { signup_styles } from "@styles/signup_styles";
import Customizedinput from "@components/Customizedinput";
import Customizedbutton from "@components/Customizedbutton";
import "@/App.css";
import { style_exports } from "@exports/style_exports";
import { Regex_exports } from "@exports/Regex_eports";
import Forgotpwd from "@models/Forgotpwd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  emailchecking,
  mobilechecking,
  passwordchecking,
  pwd_cnfmpwd_validation,
} from "@validations/Commonvalidations";
import { LoginActions } from "@store/Loginslice";
import { useDispatch } from "react-redux";
import SocialButton from "@components/SocialButton";
import { Envexports } from "@exports/Envexports";
import { gapi } from "gapi-script";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import Otpverification from "@models/Otpverification";
import Countrycode from "@models/Countrycode";
import Emailverification from "@models/Emailverification";
import Resetpwd from "@models/Resetpwd";
import Vieweye from "@components/Vieweye";
import Hideeye from "@components/Hideeye";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import Loadingcontext from "@parts/Loadingprovider";
import { loginapi, registerapi } from "@services/Userservices";
import { StorageClient } from "@helpers/Helperfunctions";
import { defaultcountry_code } from "@components/constants/Userconstants";
import { emptystringcheck } from "@validations/Commonvalidations";
import { detectIncognito } from "detectincognitojs";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { getall_countries } from "@services/Commonservices";
import Restoreaccount from "@models/Restoreaccount";
import { sociallogins } from "@services/Userservices";
import Contactadmin from "@models/Contactadmin";
import Customizedselectsearch from "@components/Customizedselectsearch";
import { completeprofile_Actions } from "../store/Completeprofileslice";
import { AutoSaveActions } from "@store/AutoSaveSlice";
import { capitalizeletter } from "../helpers/Helperfunctions";
import ReCAPTCHA from "react-google-recaptcha";
import { registrationInitialValues } from "../components/constants/Userconstants";
import CommonImage from "@components/CommonImage";

const Signuporlogin = () => {
  // const { customEnqueueSnackbar } = useSnackbar();
  const { setLoading } = useContext(Loadingcontext);
  const location = useLocation();
  const loginstyles = signup_styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

/**----------------------------------------------States Declaration and Initialization--------------------------------- */
  const [login, setLogin] = useState(1);
  const [login_details, setLogin_details] = useState({
    email: "",
    password: "",
    passwordstatus: false,
  });
  const [otpmodel, setOtpmodel] = useState(false);
  const [email_model, setEmail_model] = useState(false);
  const [restore_model, setRestore_model] = useState(false);
  const [social_details, setSocialdetails] = useState({
    socialid: "",
    type: 0,
  });
  const [frgtpwd, setFrgtpwd] = useState(false);
  const [all_countries, setAll_countries] = useState([]);
  const [google_recaptcha, setGoogle_recaptcha] = useState("");
  const [register_details, setRegister_details] = useState(registrationInitialValues);
  // This login_data is used for two factor autentication
  const [login_data, setLogin_data] = useState({});
  const [mobile_login, setMobile_login] = useState(false);
  const [selcountry_code, setSelcountry_code] = useState(defaultcountry_code);
  const [reset_pwd, setReset_pwd] = useState(false);
  const [countrycode, setCountrycode] = useState(false);
  const [email_final, setEmail_final] = useState(0);
  const [otpopen_type, setOtpopen_type] = useState(0);
  const [frgtpwd_data, setFrgtpwd_data] = useState({
    phn: "",
    countrycode: {},
  });
  const [visitorId, setVisitorId] = useState("");
  const [user_blocked, setUser_blocked] = useState(false);

/**-----------------------------------------------Functions------------------------------------------------- */

  // Updating the input state
  const handleinputchange = (event) => {
    const { name, value } = event.target;
    if (name === "phn_num" && isNaN(value)) {
      return;
    }
    if (login) {
      if (name === "email") {
        if (Regex_exports.mobile_regex.test(value) && value.length >= 3) {
          setMobile_login(true);
        } else {
          setMobile_login(false);
        }
      }
      setLogin_details((prevstate) => ({
        ...prevstate,
        [name]: value.replace(Regex_exports.extra_space, " ").trim(),
      }));
    } else {
      setRegister_details((prevstate) => ({
        ...prevstate,
        [name]: value.replace(Regex_exports.extra_space, " ").trimStart(),
      }));
    }
  };

  // Login api calling
  const handlelogin = async () => {
    /**login validations */
    if (
      !mobile_login &&
      emailchecking(login_details.email, "Email or Mobile number")
    )
      return;
    if (
      mobile_login &&
      mobilechecking(login_details?.email, "Email or Mobile number")
    )
      return;
    if (login_details.password === "") {
      customEnqueueSnackbar(
        "Oops! You forgot to enter your Password. Please provide it.",
        { variant: "warning" }
      );
      return;
    }
    // if(google_recaptcha === ""){
    //   customEnqueueSnackbar("Please verify captcha", { variant: "warning" });
    //   return;
    // }
    try {
      setLoading(true);
      const login_req = {
        emailOrPhone: login_details?.email,
        password: login_details?.password,
        type: mobile_login ? 2 : 1,
        socialId: "",
        socialType: "",
        countryCode: mobile_login ? selcountry_code?.id : "",
        deviceId: visitorId,
        platformType: 3,
      };
      const login_res = await loginapi(login_req);
      setLoading(false);
      switch (login_res?.status) {
        case 1:
          setLogin_data({ ...login_res?.data, socialogin: 0 });
          handlelogin_result(login_res?.data);
          break;
        case 2:
          // Deactivation
          setLogin_data({ ...login_res?.data, socialogin: 0 });
          dispatch(LoginActions.userlogin([0, login_res?.data]));
          setRestore_model(true);
          break;
        case 3:
          // user blocked by admin
          setUser_blocked(true);
          break;
        default:
          customEnqueueSnackbar(login_res?.message, { variant: "error" });
          break;
      }
      if (login_res?.status) {
        dispatch(
          completeprofile_Actions.curency_update(login_res?.data?.country)
        );
        StorageClient.setItem(
          "currencycountry",
          login_res?.data?.country ?? {}
        );
        StorageClient.setItem(
          "currency",
          login_res?.data?.country?.currency ?? ""
        );
        StorageClient.setItem(
          "baseCurrency",
          login_res?.data?.country?.currency ?? ""
        );
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      customEnqueueSnackbar(err, { variant: "warning" });
    }
  };

  // Register api calling
  const handleregister = async () => {
    if (emptystringcheck(register_details.firstname, "first name")) return;
    if (emptystringcheck(register_details.lastname, "last name")) return;
    if (Object.keys(register_details?.country).length <= 0) {
      customEnqueueSnackbar("Please select the country.", { variant: "warning" });
      return;
    }
    if (mobilechecking(register_details.phn_num, "Phone number")) return;
    if (emailchecking(register_details.email, "Email")) return;
    if (register_details?.email === "" && register_details?.phn_num === "") {
      customEnqueueSnackbar("Please enter email or mobile number.", {
        variant: "warning",
      });
      return;
    }
    if (passwordchecking(register_details.pwd, "Password", 1)) return;
    if (pwd_cnfmpwd_validation(register_details.pwd, register_details.cnfmpwd))
      return;

    const register_req = {
      firstName: capitalizeletter(register_details?.firstname),
      lastName: capitalizeletter(register_details?.lastname),
      password: register_details?.pwd,
      platformType: 3,
      email: register_details?.email,
      confirmPassword: register_details?.pwd,
      phoneNumber: register_details?.phn_num,
      countryCode: selcountry_code?.id,
      socialId: social_details?.type ? social_details?.socialid : "",
      socialType: social_details?.type ? social_details?.type : 0,
      deviceId: visitorId,
      country: register_details?.country?.id,
    };
    if (location?.state?.id) {
      register_req.isAdmin = 1;
      register_req.adminId = location?.state?.id;
    }
    try {
      setLoading(true);
      const register_data = await registerapi(register_req);
      setLoading(false);
      if (register_data?.status) {
        dispatch(
          completeprofile_Actions.curency_update(register_details?.country)
        );
        StorageClient.setItem(
          "currencycountry",
          register_details?.country ?? {}
        );
        StorageClient.setItem(
          "currency",
          register_details?.country?.currency ?? ""
        );
        StorageClient.setItem(
          "baseCurrency",
          register_details?.country?.currency ?? ""
        );

        if (location?.state?.id) {
          dispatch(LoginActions.userlogin([1, register_data?.data]));
          dispatch(LoginActions.change_usertype());
          location?.state?.autoSaveDetails?.requiredService
            ? navigate("/mocalogin/accountcreated", {
                state: { autoSaveDetails: location?.state?.autoSaveDetails },
              })
            : navigate("/mocalogin/accountcreated");
        } else {
          setLogin_data(register_data?.data);
          if (register_details?.email && register_details?.phn_num) {
            // Email verification screen
            setEmail_model(true);
          } else if (register_details?.email !== "") {
            // Email verification screen
            setEmail_model(true);
          } else if (register_details?.phn_num !== "") {
            setOtpmodel(true);
          }
        }
      } else {
        customEnqueueSnackbar(register_data?.message, { variant: "error" });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    //
  };

  const handlelogin_result = (data) => {
    const {
      phoneNumber,
      mobileVerified,
      emailVerified,
      email,
      profileStatus,
      istwoFactorEnabled,
      countryCode,
    } = data;
    if (data?.plan && Object.keys(data?.plan).length) {
      dispatch(
        completeprofile_Actions.profile_data_update({ plan: data?.plan })
      );
    }
    /**Email and mobile is not verified */
    if (
      email !== "" &&
      phoneNumber !== "" &&
      !mobileVerified &&
      !emailVerified
    ) {
      setEmail_final(0);
      setEmail_model(true);
      return;
    } else if (email !== "" && !emailVerified) {
      /**Email is not verified */
      setEmail_final(0);
      setEmail_model(true);
      return;
    } else if (phoneNumber !== "" && !mobileVerified) {
      // /**Mobile number is not verified */
      dispatch(LoginActions.userlogin([0, data]));
      setOtpmodel(true);
      return;
    } else if (istwoFactorEnabled) {
      /**Two factor authenticaion enabled case */
      setFrgtpwd_data({
        phn: istwoFactorEnabled === 1 ? email : phoneNumber,
        countrycode: countryCode,
      });
      // setLogin_data(data);
      setOtpopen_type(3);
      setOtpmodel(true);
      return;
    } else if (profileStatus === 0) {
      /**User registered and verified but not created the profile then redirect to create profile screen */
      location?.state?.autoSaveDetails?.requiredService
        ? navigate("/mocalogin/profilecreation", {
            state: { autoSaveDetails: location?.state?.autoSaveDetails },
          })
        : navigate("/mocalogin/profilecreation");
      StorageClient.setItem("token", data?.token);
      StorageClient.setItem("refreshtoken", data?.refreshToken);
      dispatch(LoginActions.userlogin([1, data]));
      return;
    } else {
      /**If all the above case are not true then user is logged into application redirected to home page */
      dispatch(LoginActions.userlogin([1, data]));
      // setLogin_data(data);
      StorageClient.setItem("login_status", 1);
      StorageClient.setItem("token", data?.token);
      StorageClient.setItem("refreshtoken", data?.refreshToken);
      customEnqueueSnackbar("User Loggedin Succesfully", { variant: "success" });
      location?.state?.autoSaveDetails?.requiredService
        ? navigate(
            location?.state?.autoSaveDetails?.requiredService === 1
              ? "/castingcalls/createcastingcall"
              : location?.state?.autoSaveDetails?.requiredService === 2
              ? "/postrequest"
              : null,
            { state: { autoSaveDetails: location?.state?.autoSaveDetails } }
          )
        : navigate("/");
      return;
    }
  };

  // Google login succesfull
  const handleSocialLoginsuccess = async (user) => {
    try {
      let api_req;
      setRegister_details((prev) => ({
        ...prev,
        name: user?.profile?.name ?? "",
        email: user?.profile?.email ?? "",
      }));
      setSocialdetails((prev) => ({
        ...prev,
        socialid: user?.profile?.id ?? "",
        type: 1,
      }));
      /**Register the user using social login details */
      if (login) {
        /**social login */
        api_req = {
          email: user?.profile?.email,
          socialId: user?.profile?.id,
          socialType: 1,
          deviceId: visitorId,
          platformType: 3,
        };
        const social_login = await sociallogins(api_req);

        if (social_login?.status) {
          let status_val = social_login?.status;
          let social_data = social_login?.data;
          setLogin_data({ ...social_login?.data, socialogin: 1 });
          StorageClient.setItem("currencycountry", social_data?.country ?? {});
          StorageClient.setItem(
            "currency",
            social_data?.country?.currency ?? ""
          );
          StorageClient.setItem(
            "baseCurrency",
            social_data?.country?.currency ?? ""
          );
          switch (status_val) {
            case 1:
              /**Normal login */
              handlelogin_result(social_login?.data);
              break;
            case 2:
              /**Restoring the account */
              setRestore_model(true);
              break;
            case 3:
              /**Admin blocked case */
              setUser_blocked(true);
              break;
          }
        } else {
          setLogin((prev) => (prev === 1 ? 0 : 1));
          customEnqueueSnackbar(social_login?.message, { variant: "error" });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Google login failure
  const handleSocialLoginFailure = async (err) => {
    try {
      console.log(err, "error");
    } catch (err) {
      console.log(err);
    }
  };

  // getting country codes
  const handlecountry_selection = async (country) => {
    setSelcountry_code(country);
  };

  // callback from mail
  const otpmodelopen = (type, data) => {
    setOtpopen_type(type);
    if (data) {
      setFrgtpwd_data((prev) => ({
        ...prev,
        phn: data?.mail,
        countrycode: data?.countrycode,
      }));
      setLogin_data({ id: data?.userid ?? "" });
    }
    setOtpmodel(true);
  };

  // handle login signup form changes
  const handleformchanges = () => {
    if (login) {
      setLogin_details({
        email: "",
        password: "",
        passwordstatus: false,
      });
    } else {
      setRegister_details({
        name: "",
        c_name: "",
        phn_num: "",
        email: "",
        pwd: "",
        cnfmpwd: "",
        socialid: "",
        pwdstatus: false,
        cnfmpwd_status: false,
      });
    }
    setLogin((prev) => (prev === 1 ? 0 : 1));
  };

  // callback from otpverification
  const handleotpcallback = (type) => {
    if (type === 1) {
      setReset_pwd(true);
    } else if (type === 2) {
      handlelogin();
    } else {
      handleSocialLoginsuccess({
        profile: {
          email: register_details?.email,
          id: social_details?.socialid,
        },
      });
    }
  };

  /**Retrieving all countries data on initial load */
  const get_all_countries = async () => {
    try {
      const countries_res = await getall_countries({});
      if (countries_res?.status) {
        setAll_countries(countries_res?.data);
      } else {
        customEnqueueSnackbar(countries_res?.message, { variant: "error" });
      }
    } catch (err) {}
  };

  /**
   * Restoring the deactivated account by verifying the registered email and mobile number
   */
  const handlerestoreaccount = async () => {
    try {
      const { phoneNumber, mobileVerified, emailVerified, email } = login_data;
      /**Email and mobile is not verified */
      if (
        email !== "" &&
        phoneNumber !== "" &&
        !mobileVerified &&
        !emailVerified
      ) {
        setEmail_final(2);
        setEmail_model(true);
        return;
      } else if (email !== "" && !emailVerified) {
        /**Email is not verified */
        setEmail_final(3);
        setEmail_model(true);
        return;
      } else if (phoneNumber !== "" && !mobileVerified) {
        // /**Mobile number is not verified */
        setOtpopen_type(4);
        dispatch(LoginActions.userlogin([0, login_data]));
        setOtpmodel(true);
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  // On initial load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [login]);

  // gapi loader
  // useEffect(() => {
  //   gapi.load("client:auth2", () => {
  //     gapi.auth2.init({ clientId: Envexports.googleauthkey });
  //   });
  // }, []);

  useEffect(() => {
    const checkIncognitoMode = async () => {
      try {
        const fp = await FingerprintJS.load();
        // Generate the visitor ID
        const { visitorId: fingerprintVisitorId } = await fp.get();
        detectIncognito().then((result) => {
          if (result.isPrivate) {
            setVisitorId("INCOGNITO_MODE_" + fingerprintVisitorId);
          } else {
            setVisitorId(fingerprintVisitorId);
          }
        });
      } catch (error) {
        console.error("Error retrieving visitor identifier:", error);
      }
    };
    get_all_countries();
    checkIncognitoMode();
  }, []);

  useEffect(() => {
    if (location?.pathname === "/mocalogin" && location?.state?.login) {
      setLogin(1);
    }
    dispatch(
      AutoSaveActions.setAutoSaveRegister(location?.state?.autoSaveDetails)
    );
  }, [location?.state, location?.pathname]);

  return (
    <div>
      {email_model && (
        <Emailverification
          open={email_model}
          close={() => setEmail_model(false)}
          type={email_final}
          handlemobileotp={otpmodelopen}
          loginres={login_data}
        />
      )}
      {/**
       * Otp model opens
       * -->Initial register data from mobile
       * -->Two factor authentication
       * -->Restoration of deactivation account
       */}
      {otpmodel && (
        <Otpverification
          open={otpmodel}
          close={() => setOtpmodel(false)}
          type={otpopen_type}
          number={frgtpwd_data}
          reset_pwdcallback={handleotpcallback}
          loginres={login_data}
        />
      )}
      {frgtpwd && (
        <Forgotpwd
          open={frgtpwd}
          close={() => setFrgtpwd(false)}
          otpcallback={otpmodelopen}
          type={0}
        />
      )}
      {countrycode && (
        <Countrycode
          open={countrycode}
          close={() => setCountrycode(false)}
          countrycode_selection={handlecountry_selection}
        />
      )}
      {reset_pwd && (
        <Resetpwd open={reset_pwd} close={() => setReset_pwd(false)} />
      )}
      {restore_model && (
        <Restoreaccount
          open={restore_model}
          close={() => setRestore_model(false)}
          restoreaccount={handlerestoreaccount}
          loginres={login_data}
        />
      )}
      {user_blocked && (
        <Contactadmin
          open={user_blocked}
          close={() => setUser_blocked(false)}
        />
      )}
      <Container maxWidth="xl">
        <Grid
          container
          className={loginstyles.bordertop}
          justifyContent={"space-between"}
        >
          {/* <Hidden mdDown> */}
          <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
            <CommonImage
              src={login ? signupbg : registerbg}
              width="100%"
              // height={"100%"}
              style={{ objectFit: "contain" }}
            />
          </Grid>
          {/* </Hidden> */}
          <Grid item container md={5} sm={12} xs={12}>
            {/* <Container> */}
            <Grid item md={12} lg={12} xl={12} sm={12} xs={12} id="dj">
              {login ? (
                // Login form
                <>
                  <h4
                    style={{ marginTop: "8rem" }}
                    className={loginstyles.borderText}
                  >
                    Login
                  </h4>
                  <Grid container direction={"column"} mt={1} rowSpacing={3}>
                    <Grid item>
                      <Stack spacing={1}>
                        <Grid item fontWeight={"700"}>
                          <sup style={{ color: "red" }}>*</sup>Email ID/Mobile
                        </Grid>
                        <Grid item>
                          <Customizedinput
                            size={"medium"}
                            placeholder="Enter here"
                            fullWidth
                            value={login_details?.email}
                            onChange={handleinputchange}
                            onKeyDown={(event) => event.key === ' ' && event.target.name === "email" && event.preventDefault()}
                            name="email"
                            decapitalize="true"
                            InputProps={
                              mobile_login && {
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    onClick={() => setCountrycode(true)}
                                  >
                                    <Grid
                                      container
                                      sx={{ color: "grey", cursor: "pointer" }}
                                      spacing={0.4}
                                      alignItems={"center"}
                                    >
                                      <Grid
                                        item
                                        md={6}
                                        sm={6}
                                        xs={6}
                                      >{`${selcountry_code?.dialing_code}`}</Grid>
                                      <Grid item md={4} sm={4} xs={4} mt={0.8}>
                                        <KeyboardArrowDownSharpIcon
                                          sx={{
                                            color: style_exports?.skyblue_color,
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        md={1}
                                        sm
                                        xs
                                        sx={{
                                          borderRight: `1px solid ${style_exports?.shade_color}`,
                                          height: "20px",
                                          marginLeft: "4px",
                                        }}
                                      ></Grid>
                                    </Grid>
                                  </InputAdornment>
                                ),
                              }
                            }
                          />
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack spacing={1}>
                        <Grid item fontWeight={"700"}>
                          <sup style={{ color: "red" }}>*</sup>Password
                        </Grid>
                        <Grid item>
                          <Customizedinput
                            size={"medium"}
                            placeholder="Enter here"
                            fullWidth
                            name="password"
                            decapitalize="true"
                            onChange={handleinputchange}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handlelogin();
                              }
                            }}
                            type={
                              login_details?.passwordstatus
                                ? "text"
                                : "password"
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  onClick={() =>
                                    setLogin_details((prev) => ({
                                      ...prev,
                                      passwordstatus: !prev.passwordstatus,
                                    }))
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {login_details?.passwordstatus ? (
                                    <Vieweye />
                                  ) : (
                                    <Hideeye />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Grid item>
                      {/* <ReCAPTCHA
                      sitekey={Envexports.recaptchasitekey}
                      onChange={(value)=>{
                        setGoogle_recaptcha(value);
                      }}
                      /> */}
                      </Grid>
                      {/* <Grid item>
                        <Stack direction={"row"} alignItems={"center"}>
                          <Checkbox
                            sx={{ "&.Mui-checked": { color: "#04BEF8" } }}
                            disableRipple
                            icon={<CommonImage src={checkbox1} />}
                          />
                          <span>Remember me</span>
                        </Stack>
                      </Grid> */}
                      <Grid
                        item
                        sx={{
                          color: style_exports.forgt_textcolor,
                          fontWeight: "700",
                          cursor: "pointer",
                        }}
                        onClick={() => setFrgtpwd(true)}
                      >
                        Forgot password?
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                // Register form
                <>
                  <section style={{ marginTop: "3rem" }}>
                    <h4 className={loginstyles.borderText}>Register</h4>
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Stack spacing={1}>
                          <Grid item fontWeight={700}>
                            <sup style={{ color: "red" }}>*</sup>First Name/Company Name
                          </Grid>
                          <Grid item>
                            <Customizedinput
                              placeholder="Enter here"
                              size={"medium"}
                              value={capitalizeletter(register_details.firstname ?? "")}
                              fullWidth
                              name="firstname"
                              onChange={handleinputchange}
                            />
                          </Grid>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Stack spacing={1}>
                          <Grid item fontWeight={700}>
                            <sup style={{ color: "red" }}>*</sup>Last Name/Company Name
                          </Grid>
                          <Grid item>
                            <Customizedinput
                              placeholder="Enter here"
                              size={"medium"}
                              value={capitalizeletter(register_details.lastname ?? "")}
                              fullWidth
                              name="lastname"
                              onChange={handleinputchange}
                            />
                          </Grid>
                        </Stack>
                      </Grid>
                      
                      <Grid item>
                        <Stack spacing={1}>
                          <Grid item fontWeight={700}>
                            <sup style={{ color: "red" }}>*</sup>Country
                          </Grid>
                          <Grid item>
                            <Customizedselectsearch
                              type={2}
                              display_name="countryName"
                              options={all_countries}
                              value={register_details?.country?? {}}
                              capitalize={true}
                              onChange={(data) =>
                                setRegister_details((prev) => ({
                                  ...prev,
                                  country: data,
                                }))
                              }
                            />
                          </Grid>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Stack spacing={1}>
                          <Grid item fontWeight={700}>
                            <sup style={{ color: "red" }}>*</sup>Phone/Manager Number
                          </Grid>
                          <Grid item>
                            <Customizedinput
                              placeholder="Enter here"
                              size={"medium"}
                              fullWidth
                              value={register_details.phn_num}
                              name="phn_num"
                              onChange={handleinputchange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    onClick={() => setCountrycode(true)}
                                  >
                                    <Grid
                                      container
                                      sx={{ color: "grey", cursor: "pointer" }}
                                      spacing={0.3}
                                      alignItems={"center"}
                                    >
                                      <Grid
                                        item
                                        md={5.8}
                                        sm={5.8}
                                        xs={5.8}
                                      >{`${selcountry_code?.dialing_code}`}</Grid>
                                      <Grid item md={4} sm={4} xs={4} mt={0.8}>
                                        <KeyboardArrowDownSharpIcon
                                          sx={{
                                            color: style_exports?.skyblue_color,
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        md={0.1}
                                        sm={0.1}
                                        xs={0.1}
                                        sx={{
                                          borderRight: `1px solid ${style_exports?.grey_textcolor}`,
                                          height: "20px",
                                          marginLeft: "4px",
                                        }}
                                      ></Grid>
                                    </Grid>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Stack>
                      </Grid>

                      <Grid item>
                        <Stack spacing={1}>
                          <Grid item fontWeight={700}>
                            <sup style={{ color: "red" }}>*</sup>Email ID
                          </Grid>
                          <Grid item>
                            <Customizedinput
                              placeholder="Enter here"
                              size={"medium"}
                              fullWidth
                              name="email"
                              decapitalize="true"
                              value={register_details.email}
                              onChange={handleinputchange}
                              onKeyDown={(event) => event.key === ' ' && event.target.name === "email" && event.preventDefault()}
                            />
                          </Grid>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Stack spacing={1}>
                          <Grid item fontWeight={700}>
                            <sup style={{ color: "red" }}>*</sup>Password
                          </Grid>
                          <Grid item>
                            <Customizedinput
                              placeholder="Enter here"
                              size={"medium"}
                              fullWidth
                              name="pwd"
                              value={register_details.pwd}
                              onChange={handleinputchange}
                              decapitalize="true"
                              type={
                                register_details?.pwdstatus
                                  ? "text"
                                  : "password"
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    onClick={() =>
                                      setRegister_details((prev) => ({
                                        ...prev,
                                        pwdstatus: !prev.pwdstatus,
                                      }))
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {register_details?.pwdstatus ? (
                                      <Vieweye />
                                    ) : (
                                      <Hideeye />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Stack spacing={1}>
                          <Grid item fontWeight={700}>
                            <sup style={{ color: "red" }}>*</sup>Confirm
                            Password
                          </Grid>
                          <Grid item>
                            <Customizedinput
                              placeholder="Enter here"
                              size={"medium"}
                              fullWidth
                              name="cnfmpwd"
                              value={register_details.cnfmpwd}
                              decapitalize="true"
                              onChange={handleinputchange}
                              type={
                                register_details?.cnfmpwd_status
                                  ? "text"
                                  : "password"
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    onClick={() =>
                                      setRegister_details((prev) => ({
                                        ...prev,
                                        cnfmpwd_status: !prev.cnfmpwd_status,
                                      }))
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {register_details?.cnfmpwd_status ? (
                                      <Vieweye />
                                    ) : (
                                      <Hideeye />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Stack>
                      </Grid>
                    </Grid>
                  </section>
                </>
              )}
              <Grid item container direction={"column"} spacing={3} mt={2}>
                <Grid item container justifyContent={"center"}>
                  <Customizedbutton
                    data={login ? "Login" : "Register"}
                    swidth={"15rem"}
                    sheight={"3rem"}
                    onClick={login ? handlelogin : handleregister}
                    styles={{ fontWeight: 400, fontSize: "20px" }}
                  />
                </Grid>
                <Grid item>
                  <Divider
                    sx={{
                      "&::before": { borderColor: "#000" },
                      "&::after": { borderColor: "#000" },
                    }}
                  >
                    &nbsp;Or Login with&nbsp;
                  </Divider>
                </Grid>
                <Grid item container justifyContent={"center"} spacing={4}>
                  <Grid item md={6} sm={4} xs={6}>
                    <SocialButton
                      provider="google"
                      appId={Envexports.googleauthkey}
                      onLoginSuccess={handleSocialLoginsuccess}
                      onLoginFailure={handleSocialLoginFailure}
                      style={{
                        border: "none",
                        borderRadius: "2rem",
                        background: "transperent",
                        width: "100%",
                        padding: "0px",
                      }}
                    >
                      <Button
                        startIcon={<CommonImage src={social_mail} width={"90%"} />}
                        className={loginstyles.buttonstylelogin}
                        fullWidth
                        disableRipple
                      >
                        Gmail
                      </Button>
                    </SocialButton>
                  </Grid>
                  <Grid item md={6} sm={4} xs={6}>
                    <Button
                      startIcon={<CommonImage src={social_fb} width={"90%"} />}
                      className={loginstyles.buttonstylelogin}
                      fullWidth
                      disableRipple
                    >
                      Facebook
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  item
                  mb={login && "8rem"}
                  sx={{ marginBottom: { sm: "3rem", xs: "3rem" } }}
                  pb={3}
                  textAlign={"center"}
                >
                  {login
                    ? "Don’t have an account?"
                    : "Already  have an account?"}{" "}
                  <span
                    style={{
                      color: "#04BEF8",
                      fontWeight: "700",
                      fontSize: "18px",
                      cursor: "pointer",
                      textDecorationLine: "underline",
                    }}
                    onClick={() => handleformchanges()}
                  >
                    {login ? "Register" : "Login"}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            {/* </Container> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Signuporlogin;
